/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import BlockIcon from '@material-ui/icons/Block';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import AlertRequest from './AlertRequest';
import Backdrop from '@material-ui/core/Backdrop';
import {makeStyles} from '@material-ui/core/styles';
import {Card} from '@material-ui/core';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogGenerateOcorrenicia = ({
  docId,
  bopen,
  handleClose,
  handleNotiStack,
  DATA,
  setDATA,
  updateList,
}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [comment, setComment] = useState('');
  const [censComment, setCensComment] = useState('');
  const [open, setOpen] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [request, setRequest] = useState(false);
  const [toggleBackdrop, setToggleBackdrop] = useState(false);
  const [titleBackdrop, setTitleBackdrop] = useState('');
  const [descriptionBackdrop, setDescriptionBackdrop] = useState('');
  const [codeBackdrop, setCodeBackdrop] = useState(0);
  const classes = useStyles();
  const handleBackDrop = (title, description, code, open) => {
    setToggleBackdrop(open);
    setTitleBackdrop(title);
    setDescriptionBackdrop(description);
    setCodeBackdrop(code);
  };

  function close() {
    handleClose();
  }

  function onSave() {
    if (censComment !== '' && censComment.length > 7) {
      setSending(true);
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/pedidos/store/update/open/request/' +
            DATA._id +
            '?title=' +
            censComment +
            '&desc' +
            '',
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setCensComment('');
                  handleClose();
                  setSending(false);
                  handleNotiStack('Ocorrência enviada com sucesso', 'success');
                  updateList();
                  setDATA(data);
                })
                .catch(error => {
                  setSending(false);
                  handleBackDrop(
                    'Nosso servidor está passando por irregularidades.',
                    'Estamos trabalhado para resolver o mais rápido possível.',
                    500,
                    true,
                  );
                  console.log(error);
                });
            } else if (response.status === 503) {
              setSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    } else {
      handleBackDrop(
        'Descreva o motivo da ocorrência.',
        'É necessário descrever o motivo da ocorrência antes que ela seja aberta.',
        401,
        true,
      );
    }
  }

  useEffect(() => {
    setCensComment(DATA.strpt);
  }, [DATA]);

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <Backdrop
          className={classes.backdrop}
          open={toggleBackdrop}
          onClick={() => setToggleBackdrop(false)}
        >
          <Card style={{maxWidth: '70%'}}>
            <div style={{paddingLeft: 20, paddingRight: 20}}>
              <div
                style={{
                  background: '#FFDDDD',
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <p style={{color: '#ff0000'}}>Erro: {codeBackdrop}</p>
              </div>
              <div>
                <p style={{fontWeight: 'bold'}}>
                  Erro {codeBackdrop} - {titleBackdrop}
                </p>
              </div>
              <div
                style={{
                  background: '#F3F3F3',
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 1,
                  paddingBottom: 1,
                  marginTop: 0,
                }}
              >
                <p style={{fontSize: 14, marginTop: 5}}>
                  {descriptionBackdrop}
                </p>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                  marginBottom: 10,
                  width: '100%',
                  display: 'flex',
                }}
              >
                <Button style={{background: '#06448e', color: '#FFFFFF'}}>
                  Entendi.
                </Button>
              </div>
            </div>
          </Card>
        </Backdrop>
        <div
          style={{
            backgroundColor: '#FFffff',
            width: isVerySmall ? 'auto' : 300,
            height: 'auto',
            paddingLeft: 10,
            paddingRight: 10,
          }}
        ></div>
        <div
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 10,
            marginRight: 10,
            maxWidth: 300,
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              color: '#595959',
              marginLeft: 10,
              marginRight: 10,
              fontSize: 20,
            }}
          >
            Detalhes da ocorrência:
          </p>
          <div>
            <TextField
              variant="filled"
              multiline={true}
              style={{width: '100%', marginTop: 10}}
              inputProps={{
                style: {fontSize: 14},
                maxLength: 100,
              }}
              InputLabelProps={{style: {fontSize: 14}}}
              id="nome"
              label="Descreva o motivo da ocorrência..."
              defaultValue={censComment}
              value={censComment}
              onChange={event => {
                setCensComment(event.target.value);
              }}
            />
          </div>
          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>N° do pedido: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                {DATA.npd}
              </p>
            </div>
            <Divider variant="middle" />
          </div>
          <div>
            <div style={{display: 'flex', marginLeft: 10, marginRight: 10}}>
              <p>Data: </p>
              <p style={{marginLeft: 3, fontWeight: 'bold', color: '#393939'}}>
                {DATA.fday}
              </p>
            </div>
            <Divider variant="middle" />
          </div>

          {DATA.ssttrpt === '' ? null : DATA.ssttrpt !== 'Aberto' ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                marginLeft: 0,
                marginBottom: 10,
                marginTop: 0,
                paddingTop: 0,
              }}
            >
              <div style={{marginLeft: 10, marginRight: 10}}>
                <p>Resposta: </p>
                <p style={{fontSize: 10}}>{DATA.sawsrpt}</p>
              </div>
            </div>
          ) : (
            <div style={{marginRight: 0, marginLeft: 0}}>
              <div style={{marginLeft: 10, marginRight: 10}}>
                <p>Resposta: </p>
                <p style={{fontSize: 10}}>
                  O periodo de resposta da Irê it é cerca de 72 horas. Após
                  responder, a Irê it irá enviar uma notificação e um email com
                  a resposta.
                </p>
              </div>
            </div>
          )}
          <Divider variant="middle" />
        </div>
        {DATA.ssttrpt !== 'Aberto' ? (
          <DialogActions style={{position: 'relative', bottom: 0}}>
            {isVerySmall ? (
              <Button size={'small'} onClick={() => close()}>
                Cancelar
              </Button>
            ) : (
              <Button onClick={() => close()}>Cancelar</Button>
            )}
            {isSmall ? (
              <Button size={'small'} onClick={() => onSave()} color="secondary">
                Enviar
              </Button>
            ) : (
              <Button onClick={() => onSave()} color="secondary">
                Enviar
              </Button>
            )}
          </DialogActions>
        ) : (
          <DialogActions style={{position: 'relative', bottom: 0}}>
            {isSmall ? (
              <Button size={'small'} onClick={() => close()} color="primary">
                Ok
              </Button>
            ) : (
              <Button onClick={() => close()} color="primary">
                Ok
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
      <AlertRequest
        bopen={sending}
        handleClose={() => setSending(false)}
        txt={'Enviando...'}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default DialogGenerateOcorrenicia;
