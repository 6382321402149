/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import ContentMap from './ContentMap';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import {Box, CircularProgress} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import {useSelector} from 'react-redux';
import AlertSending from '../../components/AlertSeding';
import * as ActionStore from '../../store/actions/setRestaurante';
import {useDispatch} from 'react-redux';
import {getAuth} from 'firebase/auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Atendimento = ({
  handleToggleSidebar,
  handleNotiStack,
  handleBackDrop,
}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);

  const [value, setValue] = useState();
  function handlyvalue(value) {
    setValue(value);
  }
  const [raio, setRaio] = useState(1);
  const handleChange = (event, newValue) => {
    setRaio(newValue);
  };

  const [path, setPath] = useState([]);
  const [circlepath, setCirclePath] = useState([]);
  const dispatch = useDispatch();
  const docid = useSelector(state => state.setrestaurante.id);
  const estado = useSelector(state => state.setrestaurante);
  const vlrentrega = useSelector(state => state.setrestaurante.frete);
  const cdistance = useSelector(state => state.setrestaurante.bDstc);
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const clat = useSelector(
    state => state.setrestaurante.location.coordinates[1],
  );
  const clng = useSelector(
    state => state.setrestaurante.location.coordinates[0],
  );

  useEffect(() => {
    setValue(vlrentrega);
    setIsDistance(cdistance);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [estado]);

  useEffect(() => {
    creatcircle(raio, clat, clng, 15);
  }, [raio]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function creatcircle(radius, lat, lng, speed) {
    var centerLat = (lat * Math.PI) / 180.0;
    var centerLng = (lng * Math.PI) / 180.0;
    var dist = radius / 6371.0;
    var circlePoints = [];
    for (let x = 0; x <= 360; x += speed) {
      var brng = (x * Math.PI) / 180.0; //rad
      var latitude = Math.asin(
        Math.sin(centerLat) * Math.cos(dist) +
          Math.cos(centerLat) * Math.sin(dist) * Math.cos(brng),
      );
      var longitude =
        ((centerLng +
          Math.atan2(
            Math.sin(brng) * Math.sin(dist) * Math.cos(centerLat),
            Math.cos(dist) - Math.sin(centerLat) * Math.sin(latitude),
          )) *
          180.0) /
        Math.PI;
      circlePoints.push({
        lat: (latitude * 180.0) / Math.PI,
        lng: longitude,
      });
      setCirclePath(circlePoints);
    }
    return circlePoints;
  }

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();
  const [isDistance, setIsDistance] = useState(true);

  const [isSending, setIsSending] = useState(false);
  function onSave() {
    setIsSending(true);
    setTimeout(() => {
      if (value === '') {
        handleNotiStack('Adicione o valor da entrega', 'error');
      } else {
        var coordinates = [];
        if (isDistance) {
          circlepath.forEach(s =>
            coordinates.push([Number(s.lng), Number(s.lat)]),
          );
          coordinates.push(coordinates[0]);
        } else {
          path.forEach(s => coordinates.push([Number(s.lng), Number(s.lat)]));
          coordinates.push(coordinates[0]);
        }
        auth.currentUser.getIdToken().then(token => {
          const requestprod = new Request(
            'https://kops.apiireit.com/gateway/produtos/updateall/aratdm' +
              '?estid=' +
              idstore,
            {
              method: 'PUT',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify([coordinates]),
            },
          );

          const request = new Request(
            'https://kops.apiireit.com/gateway/store/update/ownarea/' +
              docid +
              '?isdstc=' +
              isDistance +
              '&lat=' +
              clat +
              '&lng=' +
              clng +
              '&raio=' +
              raio +
              '&frete=' +
              value,
            {
              method: 'PUT',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify([coordinates]),
            },
          );

          fetch(request)
            .then(response => {
              if (response.status === 200) {
                handleBackDrop('', '', 200, false);
                response
                  .json()
                  .then(data => {
                    setTimeout(() => {
                      setIsSending(false);
                      handleNotiStack(
                        'Informações atualizadas com sucesso',
                        'success',
                      );
                    }, 500);
                    dispatch(
                      ActionStore.setrestaurante(
                        data._id,
                        data.nome,
                        data.dsc,
                        data.nota,
                        data.nNotas,
                        data.cat,
                        data.entrg,
                        data.rtrda,
                        data.agdnt,
                        data.espra,
                        data.dsct,
                        data.frete,
                        data.nAv,
                        data.pdmin,
                        data.entgrts,
                        data.location,
                        data.img,
                        data.stt,
                        data.catprice,
                        data.iient,
                        data.chck,
                        data.merc,
                        data.cpm,
                        data.promoent,
                        data.bapp,
                        data.bpix,
                        data.bame,
                        data.bpicpay,
                        data.bmp,
                        data.bpaypal,
                        data.bpre,
                        data.bvir,
                        data.bvref,
                        data.bd,
                        data.bvd,
                        data.bmcd,
                        data.belod,
                        data.bhipd,
                        data.bdind,
                        data.baled,
                        data.bsood,
                        data.bvrd,
                        data.bbend,
                        data.bdisd,
                        data.btrid,
                        data.bbnsd,
                        data.bbrsd,
                        data.bbrd,
                        data.bvc,
                        data.bmcc,
                        data.beloc,
                        data.bhipc,
                        data.bdinc,
                        data.balec,
                        data.bsodc,
                        data.bvrc,
                        data.bbenc,
                        data.bdisc,
                        data.btric,
                        data.bbnsc,
                        data.bbrsc,
                        data.bbrc,
                        data.end,
                        data.num,
                        data.bairro,
                        data.cid,
                        data.est,
                        data.cnpj,
                        data.bodom,
                        data.hdom,
                        data.boseg,
                        data.hseg,
                        data.boter,
                        data.hter,
                        data.boqua,
                        data.hqua,
                        data.boqui,
                        data.hqui,
                        data.bosex,
                        data.hsex,
                        data.bosab,
                        data.hsab,
                        data.aratdm,
                        data.raio,
                        data.bdstc,
                        data.essm,
                        data.esfds,
                        data.inalta,
                        data.npd,
                        data.znid,
                        data.zntx,
                        data.iitx,
                        data.iietx,
                        data.freetx,
                        data.freetill,
                        data.lastSeenAt,
                        data.presence,
                        data.openchat,
                        data.agd,
                        data.fagdays,
                        data.dtsm,
                        data.dtfds,
                        data.ctto,
                        data.aratdmii,
                        data.mxprmetrg,
                        data.bbank,
                        data.btcnta,
                        data.bagcia,
                        data.bcnta,
                        data.bdig,
                        data.cpix,
                        data.npix,
                        data.nimg,
                        data.comp,
                        data.cep,
                        data.ctrt,
                        data.hrCtrt,
                      ),
                    );
                  })
                  .catch(error => {
                    console.log(error);
                  });
              } else if (response.status === 503) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Serviço indisponível no momento',
                  'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                  503,
                  true,
                );
                throw new Error('Something went wrong on API server!');
              } else if (response.status === 404) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Serviço não encontrado.',
                  'Por favor, entre em contato com o nosso suporte e relate o problema.',
                  404,
                  true,
                );
              } else if (response.status === 401) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Você não possui autorização ou suas credenciais expirarão.',
                  'Por favor, atualize a baixa ou refaça o processo dee login.',
                  401,
                  true,
                );
              } else if (response.status === 400) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Requisição inválida.',
                  'Confira se todos os campos estão preenchidos corretamente.',
                  400,
                  true,
                );
              } else if (response.status === 500) {
                setIsSending(false);
                setTimeout(() => {
                  handleNotiStack(
                    'Não foi possível salvar as informações',
                    'error',
                  );
                }, 1000);
                handleBackDrop(
                  'Nosso servidor está passando por irregularidades.',
                  'Estamos trabalhado para resolver o mais rápido possível.',
                  500,
                  true,
                );
              }
            })
            .finally(() => {
              fetch(requestprod)
                .then(response => {
                  if (response.status === 200) {
                    response.json(data => {});
                  }
                })
                .catch(error => {
                  console.debug(error);
                });
            })
            .catch(error => {
              setIsSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
              console.error(error);
            });
        });
      }
      setIsSending(false);
    }, 2000);
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#FFFFFF',
              width: isVerySmall || isSmall ? '98%' : 'auto',
              flex: 1,
              flexDirection: 'column',
              marginBottom: 30,
            }}
          >
            <h1 style={{marginLeft: 10, marginBottom: 0}}>
              Área de entrega própria
            </h1>
            <div style={{display: 'flex'}}>
              <Box
                boxShadow={3}
                style={{
                  width: isVerySmall || isSmall ? '80%' : 200,
                  marginTop: 10,
                  marginLeft: 10,
                  marginBottom: 10,
                  borderRadius: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                }}
              >
                <div style={{display: 'flex'}}>
                  <p style={{fontWeight: 'bold', marginBottom: 0}}>
                    Taxa de entrega
                  </p>
                </div>
                <div style={{display: 'flex'}}>
                  <CurrencyTextField
                    style={{
                      marginRight: 10,
                      width: isVerySmall ? '90%' : '100%',
                      marginTop: 20,
                      marginLeft: 10,
                      marginBottom: 10,
                    }}
                    inputProps={{
                      style: {
                        fontSize: 14,
                        padding: 6,
                        color: '#393939',
                        paddingLeft: 0,
                      },
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      style: {fontSize: 14, marginTop: 0, color: '#797979'},
                    }}
                    label="Valor da entrega"
                    variant="outlined"
                    value={value}
                    currencySymbol="R$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => handlyvalue(value)}
                  />
                </div>
              </Box>
            </div>
            <div
              style={{
                display: 'flex',
                backgroundColor: '#FFFFFF',
                flex: 1,
                flexDirection: 'column',
                marginBottom: 30,
                width: 'auto',
              }}
            >
              <ContentMap
                width={width}
                isDistance={isDistance}
                setIsDistance={setIsDistance}
                style={{width: width, height: 500}}
                raio={raio}
                setRaio={setRaio}
                handleChange={handleChange}
                path={path}
                setPath={setPath}
                circlePath={circlepath}
                setCirclePath={setCirclePath}
                isVerySmall={isVerySmall}
                isSmall={isSmall}
              />
            </div>
            <div>
              <Button
                onClick={() => onSave()}
                style={{marginLeft: 10, marginTop: 20}}
                variant="contained"
                size={'medium'}
                color="primary"
              >
                Salvar
              </Button>
            </div>
          </div>
        </div>
      )}
      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        handleNotiStack
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default Atendimento;

/* 
              <Box
              boxShadow={3}
              style={{
                width: '30%',
                marginTop: 10,
                marginLeft: 10,
                marginBottom: 10,
                borderRadius: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
              }}
            >
              <div style={{display: 'flex'}}>
                <p style={{fontWeight: 'bold', marginBottom: 0}}>
                  Tempo de entrega
                </p>
                <p style={{marginBottom: 0, marginLeft: 5}}> (Em minutos)</p>
              </div>

              <div style={{display: 'flex'}}>
                <TextField
                  type={'number'}
                  value={min}
                  onChange={event => {
                    setMin(event.target.value);
                  }}
                  style={{marginTop: 20, width: '50%', marginBottom: 10}}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      marginTop: -8,
                      color: '#797979',
                    },
                  }}
                  variant="outlined"
                  label="Min."
                />
                <TextField
                  type={'number'}
                  value={max}
                  onChange={event => {
                    setMax(event.target.value);
                  }}
                  style={{
                    marginTop: 20,
                    width: '50%',
                    marginBottom: 10,
                    marginLeft: 10,
                  }}
                  inputProps={{
                    style: {fontSize: 14, padding: 6, color: '#797979'},
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      marginTop: -8,
                      color: '#797979',
                    },
                  }}
                  variant="outlined"
                  label="Max."
                />
              </div>
            </Box>
              } */
