/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {format} from '../../../configuration/format';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Divider, TextField, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import {realFormat} from '../../../configuration/realFormat';
import {dayformat} from '../../../configuration/dayformat';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
    background: '#FFFFFF',
    backgroundColor: '#FFFFFF',
  },
}));

// eslint-disable-next-line react/prop-types
const DadosBancarios = ({
  fatSelected,
  value,
  handleNotiStack,
  txantcp,
  handleChangeTxantcp,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const pix = useSelector(state => state.setrestaurante.cpix);
  const [width, height] = useWindowSize();
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVerySmall ? (
            <div style={{}}>
              <FormControl
                style={{
                  marginLeft: -20,
                  width: '65%',
                  height: '100%',
                }}
                className={classes.formControl}
                variant="filled"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  <FormControl
                    style={{}}
                    className={classes.formControl}
                    variant="filled"
                  >
                    <FormLabel
                      component="legend"
                      style={{
                        color: '#3d3d3d',
                        fontWeight: 'bold',
                        fontSize: 18,
                        marginBottom: 10,
                      }}
                    >
                      Escolha onde será debitado
                    </FormLabel>
                    <RadioGroup
                      aria-label="Forma de pagamento"
                      name="frmpayment"
                      value={txantcp}
                      onChange={handleChangeTxantcp}
                      color="primary"
                    >
                      <Box
                        style={{
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 5,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: 10,
                          paddingBottom: 10,
                        }}
                        boxShadow={3}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <FormControlLabel
                            value="semTaxa"
                            control={<Radio color="primary" />}
                            disabled={
                              new Date().getTime() >=
                              fatSelected.edate + 30 * 24 * 60 * 60 * 1000
                            }
                            label={
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{
                                    marginTop: 10,
                                    marginBottom: 0,
                                    fontSize: 14,
                                    color: '#000000',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Pix
                                </Typography>
                                <Typography
                                  variant="body1"
                                  style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: 12,
                                    color: '#000000',
                                  }}
                                >
                                  (Sem taxa de antecipação)
                                </Typography>
                                <Typography
                                  variant="caption"
                                  style={{
                                    paddingTop: 0,
                                    color: '#06448e',
                                  }}
                                >
                                  Disponível à partir de{' '}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  style={{
                                    marginTop: 0,
                                    color: '#06448E',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {dayformat(
                                    fatSelected.edate +
                                      30 * 24 * 60 * 60 * 1000,
                                  )}
                                </Typography>
                              </div>
                            }
                          />
                          <p
                            style={{
                              width: 95,
                              fontWeight: 'bold',
                              marginLeft: 0,
                              fontSize: 14,
                            }}
                          >
                            {realFormat(fatSelected.total)}
                          </p>
                        </div>
                      </Box>

                      <Box
                        style={{
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 5,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: 20,
                          paddingBottom: 10,
                        }}
                        boxShadow={3}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <FormControlLabel
                            value="comTaxa"
                            control={<Radio color="primary" />}
                            label={
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{
                                    marginTop: 10,
                                    marginBottom: 0,
                                    fontSize: 14,
                                    color: '#000000',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Pix
                                </Typography>
                                <Typography
                                  variant="body1"
                                  style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: 12,
                                    color: '#000000',
                                  }}
                                >
                                  (Com taxa de antecipação)
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  style={{
                                    marginTop: 0,
                                    color: '#FF6600',
                                    marginBottom: 0,
                                  }}
                                >
                                  Taxa de antecipação:{' '}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  style={{
                                    marginTop: 0,
                                    color: '#FF6600',
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {fatSelected.txatcp * 100} %
                                </Typography>
                              </div>
                            }
                          />
                          <p
                            style={{
                              width: 95,
                              fontWeight: 'bold',
                              marginLeft: 0,
                              fontSize: 14,
                            }}
                          >
                            {realFormat(
                              fatSelected.total -
                                fatSelected.total * fatSelected.txatcp,
                            )}
                          </p>
                        </div>
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </div>
                <Box
                  style={{
                    marginLeft: 10,
                    marginRight: 0,
                    borderRadius: 10,
                    marginTop: 20,
                  }}
                  boxShadow={3}
                >
                  <div
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 5,
                      paddingBottom: 5,
                      backgroundColor: '#f1f1f1',
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                    }}
                  >
                    <p style={{marginBottom: 0}}>Informações do repasse</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: 14,
                          color: '#393939',
                          marginBottom: 0,
                          marginTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        Prazo para receber
                      </p>
                      <p
                        style={{
                          marginLeft: 10,
                          fontWeight: 'bold',
                          fontSize: 14,
                          color: '#393939',
                          marginTop: 0,
                          marginBottom: 0,
                          paddingBottom: 0,
                          textAlign: 'right',
                        }}
                      >
                        Até dia:{' '}
                        {dayformat(
                          new Date().getTime() + 5 * 24 * 60 * 60 * 1000,
                        )}
                      </p>
                    </div>
                    <Divider variant="fullWidth" style={{marginTop: 10}} />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 14,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        Forma de transferência:
                      </p>
                      <p
                        style={{
                          marginLeft: 10,
                          fontWeight: 'bold',
                          fontSize: 14,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                          textAlign: 'right',
                        }}
                      >
                        {'pix'}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 14,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        Pix do depósito
                      </p>
                      <p
                        style={{
                          marginLeft: 10,
                          fontWeight: 'bold',
                          fontSize: 14,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                          textAlign: 'right',
                        }}
                      >
                        {pix}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 14,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        Valor inicial
                      </p>
                      <p
                        style={{
                          marginLeft: 10,
                          fontWeight: 'bold',
                          fontSize: 14,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                          textAlign: 'right',
                        }}
                      >
                        {realFormat(fatSelected.total)}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 14,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        Taxa de antecipação
                      </p>
                      <p
                        style={{
                          marginLeft: 10,
                          fontWeight: 'bold',
                          fontSize: 14,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                          textAlign: 'right',
                        }}
                      >
                        {txantcp == 'comTaxa'
                          ? realFormat(fatSelected.txatcp * fatSelected.total)
                          : realFormat(0)}
                      </p>
                    </div>
                    <Divider variant="fullWidth" style={{marginTop: 10}} />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 16,
                          color: '#000000',
                          marginBottom: 10,
                          marginTop: 5,
                          paddingBottom: 0,
                        }}
                      >
                        Valor do repasse
                      </p>
                      <p
                        style={{
                          marginLeft: 10,
                          fontWeight: 'bold',
                          fontSize: 16,
                          color: '#000000',
                          marginBottom: 10,
                          marginTop: 5,
                          paddingBottom: 0,
                          textAlign: 'right',
                        }}
                      >
                        {txantcp == 'comTaxa'
                          ? realFormat(
                              fatSelected.total -
                                fatSelected.txatcp * fatSelected.total,
                            )
                          : realFormat(fatSelected.total)}
                      </p>
                    </div>
                  </div>
                </Box>
              </FormControl>
            </div>
          ) : isSmall ? (
            <div style={{width: width * 0.675}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  paddingBottom: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <FormControl
                  style={{
                    marginLeft: 0,
                    width: '100%',
                    height: '100%',
                    marginTop: 30,
                  }}
                  className={classes.formControl}
                  variant="filled"
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#FFFFFF',
                    }}
                  >
                    <FormControl
                      style={{}}
                      className={classes.formControl}
                      variant="filled"
                    >
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          fontSize: 18,
                          marginBottom: 10,
                        }}
                      >
                        Escolha onde será debitado
                      </FormLabel>
                      <RadioGroup
                        aria-label="Forma de pagamento"
                        name="frmpayment"
                        value={txantcp}
                        onChange={handleChangeTxantcp}
                        color="primary"
                      >
                        <Box
                          style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 5,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 10,
                            paddingBottom: 10,
                          }}
                          boxShadow={3}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FormControlLabel
                              value="semTaxa"
                              control={<Radio color="primary" />}
                              disabled={
                                new Date().getTime() >=
                                fatSelected.edate + 30 * 24 * 60 * 60 * 1000
                              }
                              label={
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    style={{
                                      marginTop: 10,
                                      marginBottom: 0,
                                      fontSize: 14,
                                      color: '#000000',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Pix
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      marginTop: 0,
                                      marginBottom: 0,
                                      fontSize: 12,
                                      color: '#000000',
                                    }}
                                  >
                                    (Sem taxa de antecipação)
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    style={{
                                      paddingTop: 0,
                                      color: '#06448e',
                                    }}
                                  >
                                    Disponível à partir de{' '}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    style={{
                                      marginTop: 0,
                                      color: '#06448E',
                                      fontSize: 16,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {dayformat(
                                      fatSelected.edate +
                                        30 * 24 * 60 * 60 * 1000,
                                    )}
                                  </Typography>
                                </div>
                              }
                            />
                            <p
                              style={{
                                width: 95,
                                fontWeight: 'bold',
                                marginLeft: 0,
                                fontSize: 14,
                              }}
                            >
                              {realFormat(fatSelected.total)}
                            </p>
                          </div>
                        </Box>

                        <Box
                          style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 5,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 20,
                            paddingBottom: 10,
                          }}
                          boxShadow={3}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <FormControlLabel
                              value="comTaxa"
                              control={<Radio color="primary" />}
                              label={
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    style={{
                                      marginTop: 10,
                                      marginBottom: 0,
                                      fontSize: 14,
                                      color: '#000000',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Pix
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      marginTop: 0,
                                      marginBottom: 0,
                                      fontSize: 12,
                                      color: '#000000',
                                    }}
                                  >
                                    (Com taxa de antecipação)
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    style={{
                                      marginTop: 0,
                                      color: '#FF6600',
                                      marginBottom: 0,
                                    }}
                                  >
                                    Taxa de antecipação:{' '}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    style={{
                                      marginTop: 0,
                                      color: '#FF6600',
                                      fontSize: 16,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {fatSelected.txatcp * 100} %
                                  </Typography>
                                </div>
                              }
                            />
                            <p
                              style={{
                                width: 95,
                                fontWeight: 'bold',
                                marginLeft: 0,
                                fontSize: 14,
                              }}
                            >
                              {realFormat(
                                fatSelected.total -
                                  fatSelected.total * fatSelected.txatcp,
                              )}
                            </p>
                          </div>
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <Box
                    style={{
                      marginLeft: 10,
                      marginRight: 0,
                      borderRadius: 10,
                      marginTop: 20,
                    }}
                    boxShadow={3}
                  >
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#f1f1f1',
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                      }}
                    >
                      <p style={{marginBottom: 0}}>Informações do repasse</p>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            marginTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          Prazo para receber
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#393939',
                            marginTop: 0,
                            marginBottom: 0,
                            paddingBottom: 0,
                            textAlign: 'right',
                          }}
                        >
                          Até dia:{' '}
                          {dayformat(
                            new Date().getTime() + 5 * 24 * 60 * 60 * 1000,
                          )}
                        </p>
                      </div>
                      <Divider variant="fullWidth" style={{marginTop: 10}} />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'normal',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          Forma de transferência:
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                            textAlign: 'right',
                          }}
                        >
                          {'pix'}
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'normal',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          Pix do depósito
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                            textAlign: 'right',
                          }}
                        >
                          {pix}
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'normal',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          Valor inicial
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                            textAlign: 'right',
                          }}
                        >
                          {realFormat(fatSelected.total)}
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'normal',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          Taxa de antecipação
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                            textAlign: 'right',
                          }}
                        >
                          {txantcp == 'comTaxa'
                            ? realFormat(fatSelected.txatcp * fatSelected.total)
                            : realFormat(0)}
                        </p>
                      </div>
                      <Divider variant="fullWidth" style={{marginTop: 10}} />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'normal',
                            fontSize: 16,
                            color: '#000000',
                            marginBottom: 10,
                            marginTop: 5,
                            paddingBottom: 0,
                          }}
                        >
                          Valor do repasse
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: '#000000',
                            marginBottom: 10,
                            marginTop: 5,
                            paddingBottom: 0,
                            textAlign: 'right',
                          }}
                        >
                          {txantcp == 'comTaxa'
                            ? realFormat(
                                fatSelected.total -
                                  fatSelected.txatcp * fatSelected.total,
                              )
                            : realFormat(fatSelected.total)}
                        </p>
                      </div>
                    </div>
                  </Box>
                </FormControl>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                  }}
                >
                  <FormControl
                    style={{
                      marginLeft: 0,
                      width: '100%',
                      height: '100%',
                      marginTop: 30,
                    }}
                    className={classes.formControl}
                    variant="filled"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#FFFFFF',
                      }}
                    >
                      <FormControl
                        style={{}}
                        className={classes.formControl}
                        variant="filled"
                      >
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'bold',
                            fontSize: 18,
                            marginBottom: 10,
                          }}
                        >
                          Escolha onde será debitado
                        </FormLabel>
                        <RadioGroup
                          aria-label="Forma de pagamento"
                          name="frmpayment"
                          value={txantcp}
                          onChange={handleChangeTxantcp}
                          color="primary"
                        >
                          <Box
                            style={{
                              paddingLeft: 10,
                              paddingRight: 10,
                              borderRadius: 5,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 10,
                              paddingBottom: 10,
                            }}
                            boxShadow={3}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <FormControlLabel
                                value="semTaxa"
                                control={<Radio color="primary" />}
                                disabled={
                                  new Date().getTime() >=
                                  fatSelected.edate + 30 * 24 * 60 * 60 * 1000
                                }
                                label={
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 0,
                                        fontSize: 14,
                                        color: '#000000',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      Pix
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        fontSize: 12,
                                        color: '#000000',
                                      }}
                                    >
                                      (Sem taxa de antecipação)
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      style={{
                                        paddingTop: -5,
                                        color: '#06448e',
                                      }}
                                    >
                                      Disponível à partir de{' '}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                      style={{
                                        marginTop: 0,
                                        color: '#06448E',
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {dayformat(
                                        fatSelected.edate +
                                          30 * 24 * 60 * 60 * 1000,
                                      )}
                                    </Typography>
                                  </div>
                                }
                              />
                              <p
                                style={{
                                  width: 95,
                                  fontWeight: 'bold',
                                  marginLeft: 0,
                                  fontSize: 14,
                                  textAlign: 'right',
                                }}
                              >
                                {realFormat(fatSelected.total)}
                              </p>
                            </div>
                          </Box>

                          <Box
                            style={{
                              paddingLeft: 10,
                              paddingRight: 10,
                              borderRadius: 5,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 20,
                              paddingBottom: 10,
                            }}
                            boxShadow={3}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <FormControlLabel
                                value="comTaxa"
                                control={<Radio color="primary" />}
                                label={
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      style={{
                                        marginTop: 10,
                                        marginBottom: 0,
                                        fontSize: 14,
                                        color: '#000000',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      Pix
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        fontSize: 12,
                                        color: '#000000',
                                      }}
                                    >
                                      (Com taxa de antecipação)
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                      style={{
                                        marginTop: 0,
                                        color: '#FF6600',
                                        marginBottom: 0,
                                      }}
                                    >
                                      Taxa de antecipação:{' '}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                      style={{
                                        marginTop: 0,
                                        color: '#FF6600',
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {fatSelected.txatcp * 100} %
                                    </Typography>
                                  </div>
                                }
                              />
                              <p
                                style={{
                                  width: 95,
                                  fontWeight: 'bold',
                                  marginLeft: 0,
                                  fontSize: 14,
                                  textAlign: 'right',
                                }}
                              >
                                {realFormat(
                                  fatSelected.total -
                                    fatSelected.total * fatSelected.txatcp,
                                )}
                              </p>
                            </div>
                          </Box>
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <Box
                      style={{
                        marginLeft: 10,
                        marginRight: 0,
                        borderRadius: 10,
                        marginTop: 20,
                      }}
                      boxShadow={3}
                    >
                      <div
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                          backgroundColor: '#f1f1f1',
                          borderTopLeftRadius: 10,
                          borderBottomLeftRadius: 10,
                        }}
                      >
                        <p style={{marginBottom: 0}}>Informações do repasse</p>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: 14,
                              color: '#393939',
                              marginBottom: 0,
                              marginTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            Prazo para receber
                          </p>
                          <p
                            style={{
                              marginLeft: 10,
                              fontWeight: 'bold',
                              fontSize: 14,
                              color: '#393939',
                              marginTop: 0,
                              marginBottom: 0,
                              paddingBottom: 0,
                              textAlign: 'right',
                            }}
                          >
                            Até dia:{' '}
                            {dayformat(
                              new Date().getTime() + 5 * 24 * 60 * 60 * 1000,
                            )}
                          </p>
                        </div>
                        <Divider variant="fullWidth" style={{marginTop: 10}} />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'normal',
                              fontSize: 14,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            Forma de transferência:
                          </p>
                          <p
                            style={{
                              marginLeft: 10,
                              fontWeight: 'bold',
                              fontSize: 14,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                              textAlign: 'right',
                            }}
                          >
                            {'pix'}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'normal',
                              fontSize: 14,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            Pix do depósito
                          </p>
                          <p
                            style={{
                              marginLeft: 10,
                              fontWeight: 'bold',
                              fontSize: 14,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                              textAlign: 'right',
                            }}
                          >
                            {pix}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'normal',
                              fontSize: 14,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            Valor inicial
                          </p>
                          <p
                            style={{
                              marginLeft: 10,
                              fontWeight: 'bold',
                              fontSize: 14,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                              textAlign: 'right',
                            }}
                          >
                            {realFormat(fatSelected.total)}
                          </p>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'normal',
                              fontSize: 14,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            Taxa de antecipação
                          </p>
                          <p
                            style={{
                              marginLeft: 10,
                              fontWeight: 'bold',
                              fontSize: 14,
                              color: '#393939',
                              marginBottom: 0,
                              paddingBottom: 0,
                              textAlign: 'right',
                            }}
                          >
                            {txantcp == 'comTaxa'
                              ? realFormat(
                                  fatSelected.txatcp * fatSelected.total,
                                )
                              : realFormat(0)}
                          </p>
                        </div>
                        <Divider variant="fullWidth" style={{marginTop: 10}} />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              fontWeight: 'normal',
                              fontSize: 16,
                              color: '#000000',
                              marginBottom: 10,
                              marginTop: 5,
                              paddingBottom: 0,
                            }}
                          >
                            Valor do repasse
                          </p>
                          <p
                            style={{
                              marginLeft: 10,
                              fontWeight: 'bold',
                              fontSize: 16,
                              color: '#000000',
                              marginBottom: 10,
                              marginTop: 5,
                              paddingBottom: 0,
                              textAlign: 'right',
                            }}
                          >
                            {txantcp == 'comTaxa'
                              ? realFormat(
                                  fatSelected.total -
                                    fatSelected.txatcp * fatSelected.total,
                                )
                              : realFormat(fatSelected.total)}
                          </p>
                        </div>
                      </div>
                    </Box>
                  </FormControl>
                </div>
              </div>
            </div>
          )}
          <div></div>
        </div>
      )}
    </div>
  );
};

export default DadosBancarios;
