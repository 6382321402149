/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RepasseBox from '../repasseBox';
import RepasseDeclineBox from '../repasseDeclineBox';

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  checkIcon: {
    fontSize: 36,
    paddingRight: 4,
    marginLeft: 5,
    color: '#39B40D',
  },
  cancelIcon: {
    fontSize: 36,
    paddingRight: 4,
    marginLeft: 5,
    color: '#FF0000',
  },
  noPayIcon: {
    fontSize: 36,
    paddingRight: 4,
    marginLeft: 5,
    color: '#FF6600',
  },
}));

// eslint-disable-next-line react/prop-types
const CheckRepasse = ({
  value,
  handleChange,
  handleChangeIndex,
  loadingCheckRepasse,
  setCheckRepasse,
  currentRepasse,
  setCurrentRepasse,
  isCheckedRepasse,
  setCheckedRepasse,
  haveRepasse,
  checkIfRequestRepasseIsDone,
  contestRepasse,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [width, height] = useWindowSize();
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    setLoading(true);
    checkIfRequestRepasseIsDone();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (value == 0) {
      setLoading(true);
      checkIfRequestRepasseIsDone();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [value]);

  return (
    <div style={{}}>
      {isLoading || loadingCheckRepasse ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          {loadingCheckRepasse ? (
            <p
              style={{
                fontSize: 16,
                color: '#393939',
              }}
            >
              Verificando...
            </p>
          ) : (
            <p
              style={{
                fontSize: 16,
                color: '#393939',
              }}
            >
              Verificando...
            </p>
          )}
        </div>
      ) : (
        <div>
          {isVerySmall ? (
            <div style={{}}>
              {haveRepasse ? (
                currentRepasse.stt == 2 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',

                        marginTop: 30,
                      }}
                    >
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: 18,
                        }}
                      >
                        O repasse foi negado
                      </FormLabel>
                      <CancelOutlined
                        color="primary"
                        className={classes.cancelIcon}
                        style={{margin: 10}}
                      />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <RepasseDeclineBox
                        currentRepasse={currentRepasse}
                        boxWidth="63%"
                        contestRepasse={contestRepasse}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',

                        marginTop: 30,
                      }}
                    >
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: 18,
                        }}
                      >
                        O repasse ja foi solicitado
                      </FormLabel>
                      <CheckCircleOutlineIcon
                        color="primary"
                        className={classes.checkIcon}
                        style={{margin: 10}}
                      />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <RepasseBox
                        currentRepasse={currentRepasse}
                        boxWidth="63%"
                      />
                    </div>
                  </div>
                )
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'left',
                    width: '50%',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MoneyOffIcon
                      color="secondary"
                      className={classes.noPayIcon}
                    />
                  </div>

                  <FormLabel
                    component="legend"
                    style={{
                      color: '#3d3d3d',
                      fontWeight: 'bold',
                      marginTop: 20,
                      textAlign: 'left',
                    }}
                  >
                    O estabelecimento ainda não solicitou o repasse. Clique no
                    botão abaixo para prosseguir com o pagamento
                  </FormLabel>
                  <Button
                    style={{
                      fontSize: 10,
                      textAlign: 'center',
                      marginTop: 20,
                    }}
                    variant="contained"
                    color="primary"
                    component="span"
                    size="large"
                    onClick={() => handleChangeIndex(1)}
                  >
                    Solicitar repasse
                  </Button>
                </div>
              )}
            </div>
          ) : isSmall ? (
            <div style={{width: width * 0.675}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  paddingBottom: 10,
                  alignItems: 'left',
                  justifyContent: 'center',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                {haveRepasse ? (
                  currentRepasse.stt == 2 ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          marginTop: 10,
                          textAlign: 'center',
                          fontSize: 18,
                        }}
                      >
                        O repasse foi recusado
                      </FormLabel>
                      <CancelOutlined
                        color="primary"
                        className={classes.cancelIcon}
                        style={{margin: 10}}
                      />
                      <RepasseDeclineBox
                        currentRepasse={currentRepasse}
                        boxWidth="75%"
                        contestRepasse={contestRepasse}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          marginTop: 10,
                          textAlign: 'center',
                          fontSize: 18,
                        }}
                      >
                        O repasse ja foi solicitado
                      </FormLabel>
                      <CheckCircleOutlineIcon
                        color="primary"
                        className={classes.checkIcon}
                        style={{margin: 10}}
                      />
                      <RepasseBox
                        currentRepasse={currentRepasse}
                        boxWidth="75%"
                      />
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'left',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <MoneyOffIcon
                        color="secondary"
                        className={classes.noPayIcon}
                      />
                    </div>

                    <FormLabel
                      component="legend"
                      style={{
                        color: '#3d3d3d',
                        fontWeight: 'bold',
                        marginTop: 20,
                        textAlign: 'center',
                      }}
                    >
                      O estabelecimento ainda não solicitou o repasse. Clique no
                      botão abaixo para prosseguir com o pagamento.
                    </FormLabel>
                    <Button
                      style={{
                        fontSize: 10,
                        textAlign: 'center',
                        marginTop: 20,
                      }}
                      variant="contained"
                      color="primary"
                      component="span"
                      size="large"
                      onClick={() => handleChangeIndex(1)}
                    >
                      Solicitar repasse
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {haveRepasse ? (
                    currentRepasse.stt == 2 ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'bold',
                            marginTop: 10,
                            textAlign: 'center',
                            fontSize: 18,
                          }}
                        >
                          O repasse foi recusado
                        </FormLabel>
                        <CancelOutlined
                          color="primary"
                          className={classes.cancelIcon}
                          style={{margin: 10}}
                        />
                        <RepasseDeclineBox
                          currentRepasse={currentRepasse}
                          boxWidth="98%"
                          contestRepasse={contestRepasse}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'bold',
                            marginTop: 10,
                            textAlign: 'center',
                            fontSize: 18,
                          }}
                        >
                          O repasse ja foi solicitado
                        </FormLabel>
                        <CheckCircleOutlineIcon
                          color="primary"
                          className={classes.checkIcon}
                          style={{margin: 10}}
                        />
                        <RepasseBox
                          currentRepasse={currentRepasse}
                          boxWidth="98%"
                        />
                      </div>
                    )
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <MoneyOffIcon
                        color="secondary"
                        className={classes.noPayIcon}
                      />
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          marginTop: 20,
                          textAlign: 'center',
                        }}
                      >
                        O estabelecimento ainda não solicitou o repasse. Clique
                        no botão abaixo para prosseguir com o pagamento
                      </FormLabel>
                      <Button
                        style={{
                          fontSize: 10,
                          textAlign: 'center',
                          marginTop: 20,
                        }}
                        variant="contained"
                        color="primary"
                        component="span"
                        size="large"
                        onClick={() => handleChangeIndex(1)}
                      >
                        Solicitar repasse
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckRepasse;
