/* eslint-disable react/prop-types */
import {Box, Button, Divider} from '@material-ui/core';
import React from 'react';
import {dayformat} from '../../configuration/dayformat';

const RepasseDeclineBox = ({currentRepasse, boxWidth, contestRepasse}) => {
  return (
    <div style={{width: boxWidth}}>
      <Box
        style={{
          marginLeft: 0,
          marginRight: 0,
          borderRadius: 10,
        }}
        boxShadow={3}
      >
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: '#f1f1f1',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          }}
        >
          <p style={{marginBottom: 0}}>Informações do repasse</p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                marginTop: 0,
                paddingBottom: 0,
              }}
            >
              Status
            </p>
            <p
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
                fontSize: 14,
                color: '#FF0000',
                marginTop: 0,
                marginBottom: 0,
                paddingBottom: 0,
                textAlign: 'right',
              }}
            >
              Negado
            </p>
          </div>
          <Divider variant="fullWidth" style={{marginTop: 10}} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              style={{
                fontWeight: 'normal',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              Descrição
            </p>
            <p
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
                textAlign: 'right',
              }}
            >
              Repasse da fatura {dayformat(currentRepasse.sday)} -{' '}
              {dayformat(currentRepasse.eday)}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              style={{
                fontWeight: 'normal',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              Motivo
            </p>
            <p
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
                textAlign: 'right',
              }}
            >
              {currentRepasse.rsn}
            </p>
          </div>
          <Divider variant="fullWidth" style={{marginTop: 10}} />
          <Button
            size={'small'}
            autoFocus
            onClick={() => contestRepasse()}
            color="primary"
          >
            Solicitar revisão
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default RepasseDeclineBox;
