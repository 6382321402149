/* eslint-disable react/prop-types */
import React, {useCallback, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import {useMediaQuery} from 'react-responsive';
import {getAuth} from 'firebase/auth';
import {useSelector, useDispatch} from 'react-redux';
import * as ActionStore from '../../store/actions/setRestaurante';
import {CircularProgress, DialogTitle, Typography} from '@material-ui/core';

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const StoreContract = ({bopen, handleBackDrop, handleNotiStack}) => {
  const auth = getAuth();
  const dispatch = useDispatch();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isSending, setSending] = useState(false);
  const store = useSelector(state => state.setrestaurante);

  function close() {}

  const onSave = useCallback(
    isCheck => {
      setSending(true);
      auth.currentUser.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/store/update/assCtrt/' +
            store.id +
            '?ass=' +
            isCheck,
          {
            method: 'PUT',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({}),
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              handleBackDrop('', '', 200, false);
              response
                .json()
                .then(data => {
                  setSending(false);
                  handleNotiStack('Contrato assinado com sucesso!', 'success');
                  dispatch(
                    ActionStore.setrestaurante(
                      data._id,
                      data.nome,
                      data.dsc,
                      data.nota,
                      data.nNotas,
                      data.cat,
                      data.entrg,
                      data.rtrda,
                      data.agdnt,
                      data.espra,
                      data.dsct,
                      data.frete,
                      data.nAv,
                      data.pdmin,
                      data.entgrts,
                      data.location,
                      data.img,
                      data.stt,
                      data.catprice,
                      data.iient,
                      data.chck,
                      data.merc,
                      data.cpm,
                      data.promoent,
                      data.bapp,
                      data.bpix,
                      data.bame,
                      data.bpicpay,
                      data.bmp,
                      data.bpaypal,
                      data.bpre,
                      data.bvir,
                      data.bvref,
                      data.bd,
                      data.bvd,
                      data.bmcd,
                      data.belod,
                      data.bhipd,
                      data.bdind,
                      data.baled,
                      data.bsood,
                      data.bvrd,
                      data.bbend,
                      data.bdisd,
                      data.btrid,
                      data.bbnsd,
                      data.bbrsd,
                      data.bbrd,
                      data.bvc,
                      data.bmcc,
                      data.beloc,
                      data.bhipc,
                      data.bdinc,
                      data.balec,
                      data.bsodc,
                      data.bvrc,
                      data.bbenc,
                      data.bdisc,
                      data.btric,
                      data.bbnsc,
                      data.bbrsc,
                      data.bbrc,
                      data.end,
                      data.num,
                      data.bairro,
                      data.cid,
                      data.est,
                      data.cnpj,
                      data.bodom,
                      data.hdom,
                      data.boseg,
                      data.hseg,
                      data.boter,
                      data.hter,
                      data.boqua,
                      data.hqua,
                      data.boqui,
                      data.hqui,
                      data.bosex,
                      data.hsex,
                      data.bosab,
                      data.hsab,
                      data.aratdm,
                      data.raio,
                      data.bdstc,
                      data.essm,
                      data.esfds,
                      data.inalta,
                      data.npd,
                      data.znid,
                      data.zntx,
                      data.iitx,
                      data.iietx,
                      data.freetx,
                      data.freetill,
                      data.lastSeenAt,
                      data.presence,
                      data.openchat,
                      data.agd,
                      data.fagdays,
                      data.dtsm,
                      data.dtfds,
                      data.ctto,
                      data.aratdmii,
                      data.mxprmetrg,
                      data.bbank,
                      data.btcnta,
                      data.bagcia,
                      data.bcnta,
                      data.bdig,
                      data.cpix,
                      data.npix,
                      data.nimg,
                      data.comp,
                      data.cep,
                      data.ctrt,
                      data.hrCtrt,
                    ),
                  );
                })
                .catch(error => {
                  setSending(false);
                  handleBackDrop(
                    'Nosso servidor está passando por irregularidades.',
                    'Estamos trabalhado para resolver o mais rápido possível.',
                    500,
                    true,
                  );
                  console.log(error);
                });
            } else if (response.status === 503) {
              setSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço indisponível no momento',
                'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
                503,
                true,
              );
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              setSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Serviço não encontrado.',
                'Por favor, entre em contato com o nosso suporte e relate o problema.',
                404,
                true,
              );
            } else if (response.status === 401) {
              setSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Você não possui autorização ou suas credenciais expirarão.',
                'Por favor, atualize a baixa ou refaça o processo dee login.',
                401,
                true,
              );
            } else if (response.status === 400) {
              setSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Requisição inválida.',
                'Confira se todos os campos estão preenchidos corretamente.',
                400,
                true,
              );
            } else if (response.status === 500) {
              setSending(false);
              setTimeout(() => {
                handleNotiStack(
                  'Não foi possível salvar as informações',
                  'error',
                );
              }, 1000);
              handleBackDrop(
                'Nosso servidor está passando por irregularidades.',
                'Estamos trabalhado para resolver o mais rápido possível.',
                500,
                true,
              );
            }
          })
          .catch(error => {
            setSending(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
            console.error(error);
          });
      });
    },
    [auth, isSending],
  );

  return (
    <div>
      <Dialog
        onClose={() => close()}
        style={{
          marginLeft: isVerySmall ? -20 : 0,
          marginRight: isVerySmall ? -20 : 0,
          paddingLeft: 0,
        }}
        aria-labelledby="customized-dialog-title"
        open={bopen}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingLeft: 40,
            paddingRight: 40,
            maxWidth: 500,
          }}
        >
          <DialogTitle>Contrato de Adesão</DialogTitle>
          <Typography variant="h6" gutterBottom>
            1. Definições e Objetivo
          </Typography>
          <Typography variant="body2" paragraph>
            Este contrato tem como objetivo regulamentar a adesão de
            estabelecimentos comerciais à plataforma Irê It, registrada sob o
            CNPJ 38.095.254/0001-03. Através de nossa plataforma, seu
            estabelecimento poderá disponibilizar produtos e serviços para os
            usuários cadastrados, seguindo as políticas de uso, código de
            conduta e as diretrizes de privacidade estabelecidas.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Responsabilidades do Estabelecimento
          </Typography>
          <Typography variant="body2" paragraph>
            Ao aderir à Irê It, o estabelecimento se compromete a:
            <ul>
              <li>
                Manter a veracidade das informações cadastradas, incluindo CNPJ,
                endereço e dados bancários.
              </li>
              <li>
                Garantir a qualidade dos produtos e serviços oferecidos através
                da plataforma.
              </li>
              <li>
                Seguir as diretrizes de conduta e atendimento estabelecidas pela
                Irê It, garantindo respeito aos consumidores.
              </li>
              <li>
                Obedecer às leis aplicáveis, incluindo legislação de defesa do
                consumidor, normas sanitárias, entre outras.
              </li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Taxas e Repasses
          </Typography>
          <Typography variant="body2" paragraph>
            O repasse dos valores referentes aos pedidos realizados na
            plataforma Irê It será realizado conforme as seguintes regras:
          </Typography>

          <Typography variant="body2" paragraph>
            <strong>Faturas Semanais:</strong> As faturas são fechadas
            semanalmente, considerando o período de domingo a sábado. O
            fechamento oficial da fatura ocorre toda quarta-feira. Os valores
            devidos ao estabelecimento, assim como eventuais cobranças, serão
            processados 30 (trinta) dias após o fechamento da fatura.
          </Typography>

          <Typography variant="body2" paragraph>
            <strong>Repasses e Cobranças:</strong> Se a maior parte das vendas
            de um período foi realizada via pagamento pelo aplicativo, a Irê It
            realizará o repasse dos valores ao estabelecimento, descontando as
            taxas aplicáveis. No entanto, se a maioria das vendas foi realizada
            via pagamento presencial diretamente ao estabelecimento, este poderá
            ser cobrado pela Irê It pelo valor equivalente às taxas aplicáveis
            sobre os pedidos feitos via plataforma.
          </Typography>

          <Typography variant="body2" paragraph>
            <strong>Taxa sobre Pedidos:</strong>A Irê It cobra uma taxa de 8%
            (oito por cento) sobre o valor total de cada pedido realizado na
            plataforma, excetuando-se o valor do frete. Essa taxa se aplica a
            pedidos com entrega normal ou retirados no estabelecimento. Para
            pedidos com valor inferior a R$ 15,00 (quinze reais), será cobrada
            uma taxa adicional de serviço de R$ 1,00 (um real).
          </Typography>

          <Typography variant="body2" paragraph>
            Caso o usuário ou o estabelecimento opte por utilizar o serviço de
            entrega via Irê It MME, a taxa cobrada será de 13% (treze por cento)
            sobre o valor total do pedido, excetuando-se o valor do frete. Da
            mesma forma, para pedidos com valor inferior a R$ 15,00 (quinze
            reais), será cobrada a taxa adicional de R$ 1,00 (um real). Nessa
            modalidade, o estabelecimento ou o cliente será o responsável pelo
            pagamento do entregador do MME, conforme as condições estabelecidas
            diretamente entre o estabelecimento e o entregador.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Uso do Aplicativo MME e Responsabilidades dos Estabelecimentos e
            Entregadores
          </Typography>
          <Typography variant="body2" paragraph>
            A Irê It disponibiliza aos estabelecimentos parceiros do aplicativo
            Irê it MME, que permite a solicitação de entregadores independentes
            para a realização de entregas de pedidos. As seguintes condições
            aplicam-se ao solicitar um parceiro Entregador da Irê it MME:
          </Typography>

          <Typography variant="body2" paragraph>
            <strong>Responsabilidade pelo Preparo e Endereço:</strong> O
            estabelecimento é integralmente responsável por garantir que o
            pedido esteja devidamente preparado, embalado e etiquetado com as
            informações corretas, incluindo o endereço de entrega. Erros na
            embalagem, no conteúdo ou na digitação do endereço correto são de
            responsabilidade do estabelecimento. A Irê It não se responsabiliza
            por pedidos entregues incorretamente devido a informações incorretas
            fornecidas pelo estabelecimento.
          </Typography>

          <Typography variant="body2" paragraph>
            <strong>Conduta dos Entregadores:</strong> Os entregadores
            cadastrados no aplicativo MME são profissionais independentes e não
            possuem vínculo empregatício com a Irê It. A conduta do entregador
            durante o serviço, incluindo comportamento inadequado, consumo,
            roubo de produtos ou outros atos ilícitos, deverá ser comunicada à
            Irê It para análise e medidas administrativas, que poderão incluir o
            desligamento do entregador da plataforma.
          </Typography>

          <Typography variant="body2" paragraph>
            <strong>Medidas em Caso de Ato Impróprio:</strong> Se o entregador,
            durante o exercício de suas atividades, cometer qualquer ato
            impróprio, como o consumo ou roubo do produto, o estabelecimento
            deverá reportar imediatamente à Irê It. Após a apuração, a Irê It
            tomará as medidas cabíveis, que podem incluir o bloqueio do
            entregador no aplicativo, reembolso ao cliente e, quando necessário,
            o acionamento de autoridades competentes. Em tais casos, o
            estabelecimento e o cliente não serão responsabilizados pela perda
            dos produtos.
          </Typography>

          <Typography variant="body2" paragraph>
            <strong>Acidentes e Danos:</strong> Em caso de acidentes envolvendo
            o entregador durante o transporte do pedido, a Irê It se compromete
            a mediar a situação entre as partes envolvidas, mas não assume
            responsabilidade legal ou financeira por eventuais danos ao pedido
            ou ferimentos ao entregador. O estabelecimento, portanto, está
            isento de qualquer ônus ou responsabilidade por acidentes que
            ocorram durante a entrega.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Política de Privacidade
          </Typography>
          <Typography variant="body2" paragraph>
            A Irê It se compromete a tratar os dados do estabelecimento de
            acordo com as normas da Lei Geral de Proteção de Dados (LGPD). Os
            dados coletados serão utilizados apenas para a execução dos serviços
            e não serão compartilhados com terceiros sem o devido consentimento.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Código de Conduta
          </Typography>
          <Typography variant="body2" paragraph>
            O estabelecimento concorda em seguir o código de conduta da Irê It,
            que inclui:
            <ul>
              <li>Respeito aos clientes e aos parceiros de entrega.</li>
              <li>
                Proibição de qualquer discriminação com base em raça, gênero,
                orientação sexual, religião ou qualquer outra característica.
              </li>
              <li>
                Compromisso com a qualidade e a integridade dos produtos e
                serviços oferecidos.
              </li>
            </ul>
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Rescisão de Contrato
          </Typography>
          <Typography variant="body2" paragraph>
            A Irê It se reserva o direito de encerrar unilateralmente este
            contrato caso o estabelecimento viole os termos de uso ou o código
            de conduta. Da mesma forma, o estabelecimento pode solicitar o
            encerramento de sua adesão à plataforma a qualquer momento, mediante
            aviso prévio.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Disposições Finais
          </Typography>
          <Typography variant="body2" paragraph>
            Fica eleito o foro da comarca de Irecê, Estado da Bahia, para
            dirimir quaisquer questões oriundas deste contrato, com renúncia
            expressa a qualquer outro, por mais privilegiado que seja.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Limitação de Responsabilidade
          </Typography>
          <Typography variant="body2" paragraph>
            A Irê It não será responsável por quaisquer perdas indiretas, danos
            incidentais, lucros cessantes ou danos emergentes resultantes da
            utilização da plataforma por parte do estabelecimento ou de qualquer
            terceiro, incluindo entregadores independentes. A responsabilidade
            da Irê It estará sempre limitada ao valor correspondente às taxas
            pagas pelo estabelecimento nos últimos três meses anteriores ao
            evento que gerou o pedido de indenização.
          </Typography>

          <Typography variant="h6" gutterBottom>
            10. Força Maior
          </Typography>
          <Typography variant="body2" paragraph>
            Nenhuma das partes será considerada em violação ou inadimplente no
            cumprimento de suas obrigações contratuais na hipótese de ocorrer um
            evento de força maior, assim entendido como qualquer evento fora do
            controle razoável da parte afetada, incluindo, mas não se limitando
            a desastres naturais, guerras, atos governamentais ou pandemias. Em
            tais casos, as obrigações contratuais serão suspensas enquanto durar
            a situação de força maior.
          </Typography>

          <Typography variant="h6" gutterBottom>
            11. Alterações nos Termos
          </Typography>
          <Typography variant="body2" paragraph>
            A Irê It poderá modificar os termos e condições deste contrato a
            qualquer momento, mediante aviso prévio de 30 (trinta) dias ao
            estabelecimento. Caso o estabelecimento não concorde com as
            alterações, poderá solicitar o cancelamento da adesão à plataforma,
            sem ônus, antes da entrada em vigor das novas condições.
          </Typography>
        </div>

        <Divider variant="middle" />
        <DialogActions style={{position: 'relative', bottom: 0}}>
          {isSending ? (
            <CircularProgress />
          ) : isSmall ? (
            <Button size={'small'} onClick={() => onSave(true)} color="primary">
              Estou ciente
            </Button>
          ) : (
            <Button onClick={() => onSave(true)} color="primary">
              Estou ciente
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StoreContract;
