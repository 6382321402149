export function setrestaurante(
  id,
  nome,
  descricao,
  nota,
  nNotas,
  cat,
  entrega,
  retirada,
  agendamento,
  espera,
  dstc,
  frete,
  nAvaliacao,
  pedidominimo,
  entregagratis,
  location,
  img,
  status,
  catprice,
  ireitentrega,
  isCkd,
  isMerc,
  isCupom,
  bpromoEntrega,
  bapp,
  bpix,
  bame,
  bpicpay,
  bmp,
  bpaypal,
  bpre,
  bvir,
  bvref,
  bd,
  bvd,
  bmcd,
  belod,
  bhipd,
  bdind,
  baled,
  bsodd,
  bvrd,
  bbend,
  bdisd,
  btrid,
  bbnsd,
  bbrsd,
  bbrd,
  bvc,
  bmcc,
  beloc,
  bhipc,
  bdinc,
  balec,
  bsodc,
  bvrc,
  bbenc,
  bdisc,
  btric,
  bbnsc,
  bbrsc,
  bbrc,
  endereco,
  numero,
  bairro,
  cidade,
  estado,
  cnpj,
  bodom,
  hdom,
  boseg,
  hseg,
  boter,
  hter,
  boqua,
  hqua,
  boqui,
  hqui,
  bosex,
  hsex,
  bosab,
  hsab,
  aratdm,
  raio,
  bDstc,
  essm,
  esfds,
  inAlta,
  npd,
  znid,
  zntx,
  iitx,
  iietx,
  freetx,
  freetill,
  lastSeenAt,
  presence,
  openchat,
  agd,
  fagdays,
  dtsm,
  dtfds,
  contato,
  aratdmii,
  mxprmetrg,
  bbank,
  btcnta,
  bagcia,
  bcnta,
  bdig,
  cpix,
  npix,
  nimg,
  comp,
  cep,
  ctrt,
  hrCtrt,
) {
  return {
    type: 'SET_RESTAURANTE_DETAILS',
    id,
    nome,
    descricao,
    nota,
    nNotas,
    cat,
    entrega,
    retirada,
    agendamento,
    espera,
    dstc,
    frete,
    nAvaliacao,
    pedidominimo,
    entregagratis,
    location,
    img,
    status,
    catprice,
    ireitentrega,
    isCkd,
    isMerc,
    isCupom,
    bpromoEntrega,
    bapp,
    bpix,
    bame,
    bpicpay,
    bmp,
    bpaypal,
    bpre,
    bvir,
    bvref,
    bd,
    bvd,
    bmcd,
    belod,
    bhipd,
    bdind,
    baled,
    bsodd,
    bvrd,
    bbend,
    bdisd,
    btrid,
    bbnsd,
    bbrsd,
    bbrd,
    bvc,
    bmcc,
    beloc,
    bhipc,
    bdinc,
    balec,
    bsodc,
    bvrc,
    bbenc,
    bdisc,
    btric,
    bbnsc,
    bbrsc,
    bbrc,
    endereco,
    numero,
    bairro,
    cidade,
    estado,
    cnpj,
    bodom,
    hdom,
    boseg,
    hseg,
    boter,
    hter,
    boqua,
    hqua,
    boqui,
    hqui,
    bosex,
    hsex,
    bosab,
    hsab,
    aratdm,
    raio,
    bDstc,
    essm,
    esfds,
    inAlta,
    npd,
    znid,
    zntx,
    iitx,
    iietx,
    freetx,
    freetill,
    lastSeenAt,
    presence,
    openchat,
    agd,
    fagdays,
    dtsm,
    dtfds,
    contato,
    aratdmii,
    mxprmetrg,
    bbank,
    btcnta,
    bagcia,
    bcnta,
    bdig,
    cpix,
    npix,
    nimg,
    comp,
    cep,
    ctrt,
    hrCtrt,
  };
}

export function setIdStore(idstore) {
  return {
    type: 'SET_IDSTORE',
    idstore,
  };
}

export function setIreitEntrega(ireitentrega) {
  return {
    type: 'SET_IREIT_ENTREGA',
    ireitentrega,
  };
}

export function setInAlta(inAlta) {
  return {
    type: 'SET_IN_ALTA',
    inAlta,
  };
}

export function setRetirada(retirada) {
  return {
    type: 'SET_RETIRADA',
    retirada,
  };
}

export function setPropria(propria) {
  return {
    type: 'SET_PROPRIA',
    propria,
  };
}

export function setAratdmii(aratdmii) {
  return {
    type: 'SET_ARATDMII',
    aratdmii,
  };
}
