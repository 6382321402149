/* eslint-disable no-unused-vars */
import './App.css';
import {store, persistor} from './store';
import {Provider} from 'react-redux';
import Routes from './routes';
import {createTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/core/styles';
import {PersistGate} from 'redux-persist/integration/react';
import React, {useState, useEffect} from 'react';
import {SnackbarProvider} from 'notistack';
import {StompSessionProvider, useSubscription} from 'react-stomp-hooks';
import firebase from 'firebase/compat/app';
import {getAuth} from 'firebase/auth';

const Mytheme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    warning: {
      light: '#ffeb3b',
      main: '#ffc107',
      dark: '#ff9800',
      contrastText: '#000',
    },
  },
});

if (firebase.apps.length === 0) {
  firebase.initializeApp({
    apiKey: 'AIzaSyADIHr5UwS75lsAsHpdTHG2u5i9y2nBpO4',
    authDomain: 'mercasadelivery.firebaseapp.com',
    databaseURL: 'https://mercasadelivery.firebaseio.com',
    projectId: 'mercasadelivery',
    storageBucket: 'mercasadelivery.appspot.com',
    messagingSenderId: '1075142419931',
    appId: '1:1075142419931:web:ef11de93606bcae5ccf3fe',
  });
}

function App() {
  const auth = getAuth();

  const getToken = () => {
    auth.currentUser.getIdToken().then(token => {
      return token;
    });
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={Mytheme}>
          <SnackbarProvider maxSnack={3}>
            <StompSessionProvider
              onConnect={() => console.log('Websocket pedidos is connect')}
              onDisconnect={() => console.log('WebSocket pedidos disconnect')}
              onWebSocketClose={() => console.log('WebSocket connection close')}
              onWebSocketError={() => console.log('Websocket connection error')}
              url={'https://kops.apiireit.com/gateway/pedidos/order-status'}
              //All options supported by @stomp/stompjs can be used here
            >
              <Routes />
            </StompSessionProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
