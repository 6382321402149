/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useLayoutEffect, useRef} from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
import messages from './messages';
import Home from './pages/Home/index';
import {IntlProvider} from 'react-intl';
import Login from './pages/Login';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import 'firebase/messaging';
import logo from './assets/logo.png';
import {useDispatch} from 'react-redux';
import * as ActionScreen from './store/actions/setStateScreen';
import * as ActionCounters from './store/actions/setCounters';
import * as ActionStore from './store/actions/setRestaurante';
import * as ActionUser from './store/actions/setUser';
import {useSelector} from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/database';
import AlertNotSuported from './components/AlertNotSuported';
import CircularProgress from '@material-ui/core/CircularProgress';
import pedidos_ringtone from './assets/pedidos_ringtone.mp3';
import {
  Button,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
import Confirmemail from './pages/confirmEmail';
import StoreContract from './components/StoreContract';

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle, setPlaying];
};

// eslint-disable-next-line react/prop-types
export default function Routes() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [newPedido, setNewPedido] = useState(false);
  const nNotifications = useSelector(state => state.setcounters.notifications);
  const agreement = useSelector(state => state.setuser.agreement);
  const [notSuported, setNotSuported] = useState(false);
  const [isReadyToOpen, setReadyToOpen] = useState(false);
  const [isCoockieMsgOpen, setCoockieMsgOpen] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [errorSendEmailVerified, setErrorSendEmailVerified] = useState(false);
  const [emailVerifiedIsSent, setEmailVerifiedIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [count, setCount] = useState(false);
  const [maxPageNot, setMaxPageNot] = useState(0);
  // Create a currentUser state
  const [user, setCurrentUser] = useState(null);
  const [authUser, setAuthUser] = useState(null);
  const auth = getAuth();
  const [DATA, setDATA] = useState([]);
  const idstore = useSelector(state => state.setrestaurante.idstore);

  useEffect(() => {
    if (location.pathname === '/pedidos') {
      dispatch(ActionScreen.setChangeScreen('Gestor'));
    } else if (location.pathname === '/fatura') {
      dispatch(ActionScreen.setChangeScreen('Faturas'));
    } else if (location.pathname === '/in') {
      dispatch(ActionScreen.setChangeScreen('Faturas'));
    }
  }, [location]);

  const [locale, setLocale] = useState('pt-BR');
  const audioEl = useRef(new Audio(pedidos_ringtone));

  const messaging = firebase.messaging();
  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          // TODO(developer): Retrieve a registration token for use with FCM.
          // ...
        } else {
          console.log('Unable to get permission to notify.');
        }
      });

      messaging.onMessage(payload => {
        dispatch(ActionCounters.setnotifications(nNotifications + 1));
        setNewPedido(true);
        setTimeout(() => {
          addNotification(payload);
          setNewPedido(false);
        }, 3000);
      });

      messaging
        .getToken({
          vapidKey:
            'BAulLYuaqQkqaOaps6hPGp8HQd9kXvvWFKJgsiyoP9DBFWBjMhGQW6hcV3eoXLCbRDHJ8dxlNWRRLkl5_5X2w-c',
        })
        .then(currentToken => {
          if (currentToken) {
            if (user != null) {
              writeUserData(user, currentToken);
            }
          } else {
            // Show permission request UI
            console.log(
              'No registration token available. Request permission to generate one.',
            );
            // ...
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
    } else {
      setTimeout(() => {
        if (user != null) {
          setNotSuported(true);
        }
      }, 2000);
    }
  }, [user]);

  useEffect(() => {
    setDATA([]);
    if (user != null) {
      auth.currentUser?.getIdToken().then(token => {
        const request = new Request(
          'https://kops.apiireit.com/gateway/notifications/page' +
            '?pageNo=' +
            0 +
            '&pageSize=' +
            15 +
            '&sortyby=' +
            '_id' +
            '&userid=' +
            idstore,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        fetch(request)
          .then(response => {
            if (response.status === 200) {
              response
                .json()
                .then(data => {
                  setTimeout(() => {
                    setDATA(data);
                    setMaxPageNot(data['Numero de Paginas: ']);
                  }, 1000);
                })
                .catch(error => {
                  console.log(error);
                });
            } else if (response.status === 503) {
              console.debug('Error 503');
              throw new Error('Something went wrong on API server!');
            } else if (response.status === 404) {
              console.debug('Error 404');
            } else if (response.status === 401) {
              console.debug('Error 401');
            } else if (response.status === 400) {
              console.debug('Error 400');
            } else if (response.status === 500) {
              console.debug('Error 500');
            }
          })
          .catch(error => {
            console.debug(error);
          });
      });
    }
  }, [user]);

  function addNotification(payload) {
    let newItem = {
      id: '100skdoskdosksodks',
      title: 'Nova notificação',
      message: 'Pesadona',
      read: false,
      type: 'pedido',
    };

    let list = DATA.map((item, index) => {
      return item;
    });

    list.push(newItem);
    setDATA({...DATA, data: list});
  }

  useEffect(() => {
    const audio = audioEl.current;
    audio.load();
    const onPlay = () => audio.play();

    if (audio && newPedido) {
      audio.addEventListener('canplaythrough', onPlay);
    }

    return () => {
      if (audio) {
        audio.pause(); // to enable garbage collection
        audio.removeEventListener('canplaythrough', onPlay);
      }
    };
  }, [newPedido]);

  useEffect(() => {
    if (agreement) {
      setCoockieMsgOpen(false);
    } else {
      setCoockieMsgOpen(true);
    }
  }, [agreement]);

  function writeUserData(userId, currenttoken) {
    firebase.database().ref('Tokens/').child(userId).set({token: currenttoken});
  }
  // Listen to onAuthStateChanged
  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        setCurrentUser(user.uid);
        setAuthUser(user);
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        dispatch(ActionStore.setIdStore(uid));
        dispatch(ActionUser.setuser(user.uid, user.email));
        console.log('is email verified: ' + user.emailVerified);
        setIsEmailVerified(user.emailVerified);
        setTimeout(() => {
          setReadyToOpen(true);
        }, 1000);
        // ...
      } else {
        setCurrentUser(null);
        setAuthUser(null);
        setTimeout(() => {
          setReadyToOpen(true);
        }, 1000);
        // User is signed out
        // ...
      }
    });
  }, []);

  const sendEmailVerification = () => {
    console.log(authUser);
    if (authUser && !authUser.emailVerified) {
      setCount(30);
      try {
        authUser.sendEmailVerification().then(() => {
          alert('Email verification sent!');
          setErrorSendEmailVerified(false);
          setIsEmailVerified(false);
          setEmailVerifiedIsSent(true);
        });
      } catch (error) {
        console.error('error: ' + error);
      }
    }
  };

  const checkEmailVerification = () => {
    if (authUser && authUser.emailVerified) {
      setErrorSendEmailVerified(false);
      setIsEmailVerified(true);
      setEmailVerifiedIsSent(false);
    }
  };

  function agreeCoockies() {
    dispatch(ActionUser.setagreement(true));
  }

  return (
    <div>
      {firebase.apps.length === 0 ? (
        <Loadingpage />
      ) : (
        <div>
          {!user ? (
            <Switch>
              <Route
                path="/"
                render={() => (
                  <IntlProvider locale={locale} messages={messages[locale]}>
                    <Login
                      user={isReadyToOpen}
                      setCurrentUser={setCurrentUser}
                      setLocale={setLocale}
                    />
                  </IntlProvider>
                )}
              ></Route>
            </Switch>
          ) : !isEmailVerified ? (
            <Confirmemail
              isEmailVerified={isEmailVerified}
              errorSendEmailVerified={errorSendEmailVerified}
              handleResendVerification={sendEmailVerification}
              checkEmailVerification={checkEmailVerification}
              isSending={isSending}
              setIsSending={setIsSending}
              count={count}
              setCount={setCount}
            />
          ) : (
            <Switch>
              <Route
                path="/"
                render={() => (
                  <IntlProvider locale={locale} messages={messages[locale]}>
                    {!isReadyToOpen ? (
                      <Loadingpage />
                    ) : (
                      <Home
                        setLocale={setLocale}
                        setNewPedido={setNewPedido}
                        newPedido={newPedido}
                        notifications={DATA}
                        setNotifications={setDATA}
                        maxPageNot={maxPageNot}
                        setMaxPageNot={setMaxPageNot}
                      />
                    )}
                  </IntlProvider>
                )}
              ></Route>
            </Switch>
          )}
          <div>
            <AlertNotSuported
              bopen={notSuported}
              handleClose={() => setNotSuported(false)}
              handleNotiStack
              style={{
                top: 0,
                position: 'absolute',
                zIndex: 3,
              }}
            />
          </div>
        </div>
      )}
      <SwipeableDrawer anchor={'bottom'} open={isCoockieMsgOpen}>
        <div
          style={{
            backgroundColor: '#FFF',
            color: '#3d3d3d',
            padding: '10px',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'space-around',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography style={{fontWeight: 'bold'}} variant="h6" gutterBottom>
            A Irê it utiliza coockies e tecnologias semelhantes!
          </Typography>
          <br />
          <div
            stye={{
              marginTop: 20,
              marginRight: 20,
              marginLeft: 20,
              display: 'flex',
              height: 200,
            }}
          >
            <JsxParser
              components={{Link}}
              jsx={
                'A Irê it coloca seu acesso em nossa análise de visitantes com o objetivo de melhorar o nosso site, mostrar conteúdos personalizados e oferecer uma ótima experiência. Para mais detalhes, verifique nossa a nossa <Link style={{color: "#06448E", fontWeight: "bold", textDecoration: "none"}}  to={{ pathname:`https://www.ireit.com.br/politica_de_privacidade`}} target="_blank">Política de privacidade</Link>.'
              }
            />
          </div>
          <br />
          <div stye={{marginTop: 20, marginRight: 20, marginLeft: 20}}>
            <JsxParser
              components={{Link}}
              jsx={
                'A <Link style={{color: "#06448E", fontWeight: "bold", textDecoration: "none"}} to={{ pathname:`https://www.ireit.com.br/politica_de_privacidade`}} target="_blank">Política de privacidade</Link> aplica-se à todos os produtos da Irê it, inclusive websites.'
              }
            />
          </div>

          <Button
            onClick={() => agreeCoockies()}
            style={{marginLeft: 10, marginTop: 50, marginBottom: 10}}
            variant="contained"
            size={'medium'}
            color="primary"
          >
            Concordar
          </Button>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

const Loadingpage = () => {
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();
  return (
    <div
      style={{
        width: width,
        height: height,
        display: 'flex',
        background: '#06448e',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img style={{width: 'auto', height: 80}} src={logo} alt="Logo" />
        <CircularProgress style={{color: '#FFFFFF', marginTop: 20}} />
      </div>
    </div>
  );
};
