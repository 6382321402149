/* eslint-disable no-unused-vars */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import {format} from '../../configuration/format';
import {Box, Button, Divider} from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DialogFat from './dialogFat';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/pt';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {getAuth} from 'firebase/auth';
import {useSelector} from 'react-redux';
import {dayformat} from '../../configuration/dayformat';

const columns = [
  {id: 'Período', label: 'Período', minWidth: 100},
  {
    id: 'fatura',
    label: 'Fatura',
    minWidth: 150,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'rcbStore',
    label: 'Recebido pelo estabelecimento',
    minWidth: 100,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'rcbii',
    label: 'Recebido via Irê it',
    minWidth: 100,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'ptm',
    label: 'Parte do Estabelecimento',
    minWidth: 100,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'pii',
    label: 'Parte da Irê it',
    minWidth: 100,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'pte',
    label: 'Parte dos entregadores',
    minWidth: 100,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'type',
    label: 'Receber/Repassar',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'Setas',
    label: '',
    minWidth: 70,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'Status',
    label: 'Total',
    minWidth: 150,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'subTotal',
    label: 'Vendas',
    minWidth: 100,
    format: value => value.toFixed(2),
  },
  {
    id: 'txii',
    label: 'Taxa Irê it',
    minWidth: 100,
    format: value => value.toFixed(2),
  },
  {
    id: 'txcr',
    label: 'Taxa de Serviço Irê it',
    minWidth: 100,
    format: value => value.toFixed(2),
  },
  {
    id: 'etrstore',
    label: 'Entregas próprias',
    minWidth: 100,
    format: value => value.toFixed(2),
  },
  {
    id: 'etrii',
    label: 'Entregas Irê it',
    minWidth: 100,
    format: value => value.toFixed(2),
  },
  {
    id: 'dscStore',
    label: 'Cupons da loja',
    minWidth: 100,
    format: value => value.toFixed(2),
  },
  {
    id: 'dscii',
    label: 'Cupons Irê it',
    minWidth: 100,
    format: value => value.toFixed(2),
  },
  {
    id: 'Setas2',
    label: '',
    minWidth: 70,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'total',
    label: 'Total',
    minWidth: 100,
  },
];

function createData(
  Período,
  fatura,
  rcbStore,
  rcbii,
  ptm,
  pii,
  pte,
  type,
  Setas,
  Status,
  subTotal,
  txii,
  txcr,
  etrstore,
  etrii,
  dscStore,
  dscii,
  Setas2,
  total,
) {
  return {
    Período,
    fatura,
    rcbStore,
    rcbii,
    ptm,
    pii,
    pte,
    type,
    Setas,
    Status,
    subTotal,
    txii,
    txcr,
    etrstore,
    etrii,
    dscStore,
    dscii,
    Setas2,
    total,
  };
}

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// eslint-disable-next-line react/prop-types
const Faturas = ({handleToggleSidebar, handleNotiStack, handleBackDrop}) => {
  const auth = getAuth();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [isSearching, setSearching] = useState(true);
  const classes = useStyles();
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [selectedInitialDate, setSelectedInitialDate] = React.useState(
    new Date(),
  );
  const handleInitialDateChange = date => {
    if (date != 'Invalid Date') {
      setSelectedInitialDate(date);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [resultsSize, setResultsize] = useState(0);
  const [fatmes, setFatmes] = useState({
    id: 'o023okd20',
    periodo: '01/09/2021 - 08/09/2021',
    subtotal: 30,
    recebido: 25,
    taxas: -10,
    outros: 3,
    total: -30,
    status: {vlr: 10, status: 'pago', data: '20/09/2021'},
  });

  const [rowSelected, setRowSelected] = useState({
    id: '',
    Periodo: '',
    Subtotal: 0,
    Recebido: 0,
    Taxas: 0,
    Outros: 0,
    Total: 0,
    Status: {vlr: 0, status: '', data: ''},
  });

  const [DATA, setDATA] = useState([]);
  const [rcbttl, setRcbttl] = useState([]);
  const [rcbtapp, setRcbtapp] = useState([]);
  const [rcbtstore, setRcbtstore] = useState([]);
  const [fatSelected, setFatSelected] = useState({itens: []});

  useEffect(() => {
    let rcbtotal = 0.0;
    let rcbii = 0.0;
    let rcbstore = 0.0;

    let map = DATA.map((item, index) => {
      let stt = '';
      let typereceive = '';

      rcbtotal = rcbtotal + item.tdin + item.tmaq + item.tapp + item.tpix;
      rcbii = rcbii + item.tapp + item.tpix;
      rcbstore = rcbstore + item.tdin + item.tmaq;

      if (item.total == 0) {
        typereceive = 'Sem repasse';
      } else if (item.total > 0) {
        typereceive = 'Receber';
      } else if (item.total < 0) {
        typereceive = 'Repassar';
      }

      if (item.total == 0) {
        stt = 'pago';
      } else if (item.stt == 0 && item.late) {
        stt = 'atrasado';
      } else if (item.total < 0 && item.stt == 0) {
        stt = 'aberto';
      } else if (item.total > 0 && item.stt == 0) {
        stt = 'aguardando';
      } else if (item.total < 0 && item.stt == 1) {
        stt = 'aberto';
      } else if (item.total > 0 && item.stt == 1) {
        stt = 'agendado';
      } else if (item.total < 0 && item.stt == 2) {
        stt = 'pago';
      } else if (item.total > 0 && item.stt == 2) {
        stt = 'pago';
      }

      return createData(
        dayformat(item.sdate) + ' - ' + dayformat(item.edate),
        {
          vlr: item.total < 0 ? item.total * -1 : item.total,
          type: item.total > 0 ? 'Repasse' : item.total < 0 ? 'Pagar' : '',
          disableButton: stt == 'aberto' || stt == 'atrasado' ? false : true,
          status: stt,
          data:
            stt == 'pago'
              ? dayformat(item.hrpgmt)
              : stt == 'agendado'
              ? dayformat(item.expagd)
              : dayformat(item.exp),
        },
        item.tdin + item.tmaq,
        item.tapp + item.tpix,
        item.ptm,
        item.pii + item.ttxcr,
        item.pte,
        typereceive,
        '',
        {
          vlr: item.total < 0 ? item.total * -1 : item.total,
          type: item.total > 0 ? 'Repasse' : item.total < 0 ? 'Pagar' : '',
          disableButton: stt == 'aberto' || stt == 'atrasado' ? false : true,
          status: stt,
          data:
            stt == 'pago'
              ? dayformat(item.hrpgmt)
              : stt == 'agendado'
              ? dayformat(item.expagd)
              : dayformat(item.exp),
        },
        item.tsubt,
        item.pii,
        item.ttxcr,
        item.tfrete,
        item.tfreteii,
        item.tdsc,
        item.tdscii,
        '',
        {
          vlr: item.total < 0 ? item.total * -1 : item.total,
          type: item.total > 0 ? 'Repasse' : item.total < 0 ? 'Pagar' : '',
          disableButton: stt == 'aberto' || stt == 'atrasado' ? false : true,
          status: stt,
          data:
            stt == 'pago'
              ? dayformat(item.hrpgmt)
              : stt == 'agendado'
              ? dayformat(item.expagd)
              : dayformat(item.exp),
        },
      );
    });
    setRows(map);
    setResultsize(DATA.length);
    setRcbtapp(rcbii);
    setRcbtstore(rcbstore);
    setRcbttl(rcbtotal);
  }, [DATA]);

  useEffect(() => {
    getFaturaMes();
  }, [selectedInitialDate]);

  const [rows, setRows] = useState();

  const [value, setValue] = useState();
  function handlyvalue(value) {
    setValue(value);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setTimeout(() => {
      setSearching(false);
    }, 2000);
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [docId, setDocId] = useState('');
  function openDialogFat(row, index) {
    setRowSelected(row);
    setFatSelected(DATA[index]);
    setOpenDialog(true);
  }

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  function firstdaymonth(data) {
    return new Date(data.getFullYear(), data.getMonth(), 1, 1, 0, 0, 0);
  }

  // Função para obter o último dia do mês
  function lastdaymonth(data) {
    return new Date(
      data.getFullYear(),
      data.getMonth() + 1,
      0,
      23,
      59,
      59,
      999,
    );
  }

  function getFaturaMes() {
    var sdate = firstdaymonth(selectedInitialDate);
    var edate = lastdaymonth(selectedInitialDate);
    setSearching(true);
    auth.currentUser.getIdToken().then(token => {
      const clearrequest = new Request(
        'https://kops.apiireit.com/gateway/faturas/store/get?user=' +
          idstore +
          '&sdate=' +
          sdate.getTime() +
          '&edate=' +
          edate.getTime(),
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(clearrequest)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setTimeout(() => {
                  setDATA(data);
                  setSearching(false);
                }, 1000);
              })
              .catch(error => {
                console.log(error);
              });
          } else if (response.status === 503) {
            setSearching(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            setSearching(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            setSearching(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            setSearching(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            setSearching(false);
            setTimeout(() => {
              handleNotiStack(
                'Não foi possível salvar as informações',
                'error',
              );
            }, 1000);
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          setSearching(false);
          setTimeout(() => {
            handleNotiStack('Não foi possível salvar as informações', 'error');
          }, 1000);
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          {isVerySmall || isSmall ? (
            <div style={{marginLeft: 10, marginRight: 10}}>
              <h1>Faturas</h1>
              <div style={{}}>
                <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="MM/yyyy"
                    views={['year', 'month']}
                    margin="normal"
                    id="date-picker-dialog1"
                    label="Escolha o mês da fatura"
                    value={selectedInitialDate}
                    onChange={handleInitialDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{
                      style: {
                        fontSize: 14,
                        height: 44,
                      },
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <Button
                onClick={() => getFaturaMes()}
                style={{marginLeft: 0, marginTop: 10}}
                variant="contained"
                size={'medium'}
                color="primary"
              >
                Buscar
              </Button>
              {isSearching ? (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: height / 2,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <div>
                  <div style={{justifyContent: 'space-between'}}>
                    <p style={{fontSize: 20, color: '#393939'}}>
                      Visão geral de vendas
                    </p>
                    <Button
                      style={{paddingTop: 0, paddingBottom: 0}}
                      size={'small'}
                      variant="outlined"
                      color="primary"
                    >
                      <ReceiptIcon fontSize="small" />
                      Nota fiscal
                    </Button>
                  </div>

                  <p
                    style={{
                      fontSize: 14,
                      color: '#999999',
                      marginTop: 10,
                      paddingTop: 0,
                    }}
                  >
                    Aqui você irá ter uma visão do balanço geral da parte da Irê
                    it e a sua no mês.
                  </p>
                  <Box
                    style={{
                      marginLeft: 0,
                      marginRight: 0,
                      borderRadius: 10,
                    }}
                    boxShadow={3}
                  >
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#f1f1f1',
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                      }}
                    >
                      <p style={{}}>Total de vendas</p>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          marginTop: -30,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          R$
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                            fontSize: 30,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {format(rcbttl)}
                        </p>
                      </div>
                      <Button
                        size={'small'}
                        variant="outlined"
                        color="primary"
                        style={{
                          color: '#06448e',
                          marginTop: 10,
                          paddingTop: 3,
                          fontSize: 10,
                          marginLeft: -5,
                        }}
                      >
                        Total de vendas concluidas
                      </Button>
                    </div>
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        alignItems: 'center',
                      }}
                    >
                      <p style={{}}>Recebido pela Irê it</p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: 20,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        R$ {format(rcbtapp)}
                      </p>
                    </div>
                    <Divider variant="middle" />
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        alignItems: 'center',
                      }}
                    >
                      <p style={{}}>Recebido pela loja</p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: 20,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        R$ {format(rcbtstore)}
                      </p>
                    </div>
                  </Box>
                  <div style={{marginTop: 30, marginBottom: 200}}>
                    <Paper sx={{width: '100%'}}>
                      <TableContainer sx={{maxHeight: 440}}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{fontSize: 20}}
                                align="left"
                                colSpan={2}
                              >
                                Fatura
                              </TableCell>
                              <TableCell
                                style={{fontSize: 20}}
                                align="left"
                                colSpan={8}
                              >
                                Repasse
                              </TableCell>
                              <TableCell
                                style={{fontSize: 20}}
                                align="left"
                                colSpan={9}
                              >
                                Vendas
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {columns.map(column => (
                                <TableCell
                                  key={column.id}
                                  align="left"
                                  style={{
                                    top: 57,
                                    minWidth: column.minWidth,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                              )
                              .map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.code}
                                    onClick={() =>
                                      openDialogFat(rows[index], index)
                                    }
                                  >
                                    {columns.map(column => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={'left'}
                                        >
                                          {column.id === 'Setas' ||
                                          column.id === 'Setas2' ? (
                                            <ArrowRightAltIcon />
                                          ) : column.id === 'Período' ||
                                            column.id === 'type' ? (
                                            <p>{value}</p>
                                          ) : column.id === 'Status' ||
                                            column.id === 'fatura' ||
                                            column.id === 'total' ? (
                                            <div>
                                              {value.status === 'pago' ? (
                                                <div>
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        '#eeffee',
                                                      alignItems: 'center',
                                                      paddingLeft: 10,
                                                      paddingTop: 5,
                                                      paddingBottom: 0,
                                                      borderRadius: 5,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          fontSize: 14,
                                                          marginTop: 0,
                                                          marginRight: 3,
                                                          color:
                                                            'rgba(57, 180, 13, 1)',
                                                        }}
                                                      >
                                                        R$
                                                      </p>
                                                      <p
                                                        style={{
                                                          fontSize: 20,
                                                          fontWeight: 'bold',
                                                          marginTop: -1,
                                                          color:
                                                            'rgba(57, 180, 13, 1)',
                                                        }}
                                                      >
                                                        {format(value.vlr)}
                                                      </p>
                                                    </div>
                                                    <p
                                                      style={{
                                                        marginTop: -20,
                                                        fontSize: 12,
                                                        color:
                                                          'rgba(57, 180, 13, 1)',
                                                      }}
                                                    >
                                                      Pago em {value.data}
                                                    </p>
                                                  </div>
                                                </div>
                                              ) : value.status ===
                                                'agendado' ? (
                                                <div
                                                  style={{
                                                    backgroundColor: '#f4f4f4',
                                                    alignItems: 'center',
                                                    paddingLeft: 10,
                                                    paddingTop: 5,
                                                    paddingBottom: 0,
                                                    borderRadius: 5,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        fontSize: 14,
                                                        marginTop: 0,
                                                        marginRight: 3,
                                                      }}
                                                    >
                                                      R$
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize: 20,
                                                        fontWeight: 'bold',
                                                        marginTop: -1,
                                                      }}
                                                    >
                                                      {format(value.vlr)}
                                                    </p>
                                                  </div>
                                                  <p
                                                    style={{
                                                      marginTop: -20,
                                                      fontSize: 12,
                                                      color: '#999999',
                                                    }}
                                                  >
                                                    Repasse previsto até{' '}
                                                    {value.data}
                                                  </p>
                                                </div>
                                              ) : value.status === 'aberto' ? (
                                                <div
                                                  style={{
                                                    backgroundColor: '#ffffff',
                                                    alignItems: 'center',
                                                    paddingLeft: 10,
                                                    paddingTop: 5,
                                                    paddingBottom: 0,
                                                    borderRadius: 5,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        fontSize: 14,
                                                        marginTop: 0,
                                                        marginRight: 3,
                                                      }}
                                                    >
                                                      R$
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize: 20,
                                                        fontWeight: 'bold',
                                                        marginTop: -1,
                                                      }}
                                                    >
                                                      {format(value.vlr)}
                                                    </p>
                                                  </div>
                                                  <p
                                                    style={{
                                                      marginTop: -20,
                                                      fontSize: 12,
                                                      color: '#999999',
                                                    }}
                                                  >
                                                    Em aberto
                                                  </p>
                                                </div>
                                              ) : value.status ===
                                                'atrasado' ? (
                                                <div
                                                  style={{
                                                    backgroundColor: '#fff4f4',
                                                    alignItems: 'center',
                                                    paddingLeft: 10,
                                                    paddingTop: 5,
                                                    paddingBottom: 0,
                                                    borderRadius: 5,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        fontSize: 14,
                                                        marginTop: 0,
                                                        marginRight: 3,
                                                        color: '#ff3333',
                                                      }}
                                                    >
                                                      R$
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize: 20,
                                                        fontWeight: 'bold',
                                                        marginTop: -1,
                                                        color: '#ff3333',
                                                      }}
                                                    >
                                                      {format(value.vlr)}
                                                    </p>
                                                  </div>
                                                  <p
                                                    style={{
                                                      marginTop: -20,
                                                      fontSize: 12,
                                                      color: '#ff9999',
                                                    }}
                                                  >
                                                    Em atraso
                                                  </p>
                                                </div>
                                              ) : value.status ===
                                                'aguardando' ? (
                                                <div>
                                                  {value.vlr < 0 ? (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#ffffcc',
                                                        alignItems: 'center',
                                                        paddingLeft: 10,
                                                        paddingTop: 5,
                                                        paddingBottom: 0,
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            fontSize: 14,
                                                            marginTop: 0,
                                                            marginRight: 3,
                                                            color: '#ff6600',
                                                          }}
                                                        >
                                                          R$
                                                        </p>
                                                        <p
                                                          style={{
                                                            fontSize: 20,
                                                            fontWeight: 'bold',
                                                            marginTop: -1,
                                                            color: '#ff6600',
                                                          }}
                                                        >
                                                          {format(value.vlr)}
                                                        </p>
                                                      </div>
                                                      <p
                                                        style={{
                                                          marginTop: -20,
                                                          fontSize: 12,
                                                          color: '#ff6600',
                                                        }}
                                                      >
                                                        Saldo devedor
                                                      </p>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#e8ffff',
                                                        alignItems: 'center',
                                                        paddingLeft: 10,
                                                        paddingTop: 5,
                                                        paddingBottom: 0,
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            fontSize: 14,
                                                            marginTop: 0,
                                                            marginRight: 3,
                                                            color: '#06448e',
                                                          }}
                                                        >
                                                          R$
                                                        </p>
                                                        <p
                                                          style={{
                                                            fontSize: 20,
                                                            fontWeight: 'bold',
                                                            marginTop: -1,
                                                            color: '#06448e',
                                                          }}
                                                        >
                                                          {format(value.vlr)}
                                                        </p>
                                                      </div>
                                                      <p
                                                        style={{
                                                          marginTop: -20,
                                                          fontSize: 12,
                                                          color: '#06448e',
                                                        }}
                                                      >
                                                        Aguardando agendamento.
                                                        Previsão: até{' '}
                                                        {value.data}
                                                      </p>
                                                    </div>
                                                  )}
                                                </div>
                                              ) : null}
                                              {value.disableButton ? null : value.type ==
                                                'Pagar' ? (
                                                <Button
                                                  size={'small'}
                                                  variant="outlined"
                                                  color="secondary"
                                                  style={{
                                                    color: '#FF6600',
                                                    marginTop: 10,
                                                    paddingTop: 3,
                                                    fontSize: 10,
                                                    marginLeft: -5,
                                                  }}
                                                >
                                                  Pagar
                                                </Button>
                                              ) : value.type == '' ? null : (
                                                <Button
                                                  size={'small'}
                                                  variant="outlined"
                                                  color="primary"
                                                  style={{
                                                    color: '#06448e',
                                                    marginTop: 10,
                                                    paddingTop: 3,
                                                    fontSize: 10,
                                                    marginLeft: -5,
                                                  }}
                                                >
                                                  Solicitar repasse
                                                </Button>
                                              )}
                                            </div>
                                          ) : (
                                            'R$' + format(value)
                                          )}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={{marginLeft: 10, marginRight: 10, width: width * 0.75}}>
              <h1>Faturas</h1>
              <div style={{display: 'flex'}}>
                <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="MM/yyyy"
                    views={['year', 'month']}
                    margin="normal"
                    id="date-picker-dialog1"
                    label="Escolha o mês da fatura"
                    value={selectedInitialDate}
                    onChange={handleInitialDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{
                      style: {
                        fontSize: 14,
                        height: 44,
                      },
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <Button
                onClick={() => getFaturaMes()}
                style={{marginLeft: 0, marginTop: 10}}
                variant="contained"
                size={'medium'}
                color="primary"
              >
                Buscar
              </Button>
              {isSearching ? (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: height / 2,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <div>
                  <div
                    style={{display: 'flex', justifyContent: 'space-between'}}
                  >
                    <p style={{fontSize: 20, color: '#393939'}}>
                      Visão geral de vendas
                    </p>
                    <Button
                      style={{paddingTop: 0, paddingBottom: 0}}
                      size={'small'}
                      variant="outlined"
                      color="primary"
                    >
                      <ReceiptIcon fontSize="small" />
                      Nota fiscal
                    </Button>
                  </div>

                  <p
                    style={{
                      fontSize: 14,
                      color: '#999999',
                      marginTop: -15,
                      paddingTop: 0,
                    }}
                  >
                    Aqui você irá ter uma visão do balanço geral da parte da Irê
                    it e a sua no mês.
                  </p>
                  <Box
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      borderRadius: 10,
                      display: 'flex',
                    }}
                    boxShadow={3}
                  >
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        backgroundColor: '#f1f1f1',
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                      }}
                    >
                      <p style={{}}>Total de vendas</p>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          marginTop: -30,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          R$
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                            fontWeight: 'bold',
                            fontSize: 30,
                            color: '#393939',
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          {format(rcbttl)}
                        </p>
                      </div>
                      <Button
                        size={'small'}
                        variant="outlined"
                        color="primary"
                        style={{
                          color: '#06448e',
                          marginTop: 10,
                          paddingTop: 3,
                          fontSize: 10,
                          marginLeft: -5,
                        }}
                      >
                        Total de vendas concluidas
                      </Button>
                    </div>
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        alignItems: 'center',
                      }}
                    >
                      <p style={{}}>Recebido pela Irê it</p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: 20,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        R$ {format(rcbtapp)}
                      </p>
                    </div>
                    <div
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                        alignItems: 'center',
                      }}
                    >
                      <p style={{}}>Recebido pela loja</p>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: 20,
                          color: '#393939',
                          marginBottom: 0,
                          paddingBottom: 0,
                        }}
                      >
                        R$ {format(rcbtstore)}
                      </p>
                    </div>
                  </Box>
                  <div style={{marginTop: 30, marginBottom: 200}}>
                    <Paper sx={{width: '100%'}}>
                      <TableContainer sx={{maxHeight: 440}}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{fontSize: 20}}
                                align="left"
                                colSpan={2}
                              >
                                Fatura
                              </TableCell>
                              <TableCell
                                style={{fontSize: 20}}
                                align="left"
                                colSpan={8}
                              >
                                Repasse
                              </TableCell>
                              <TableCell
                                style={{fontSize: 20}}
                                align="left"
                                colSpan={9}
                              >
                                Vendas
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {columns.map(column => (
                                <TableCell
                                  key={column.id}
                                  align="left"
                                  style={{
                                    top: 57,
                                    minWidth: column.minWidth,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                              )
                              .map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.code}
                                    onClick={() =>
                                      openDialogFat(rows[index], index)
                                    }
                                  >
                                    {columns.map(column => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={'left'}
                                        >
                                          {column.id === 'Setas' ||
                                          column.id === 'Setas2' ? (
                                            <ArrowRightAltIcon />
                                          ) : column.id === 'Período' ||
                                            column.id === 'type' ? (
                                            <p>{value}</p>
                                          ) : column.id === 'Status' ||
                                            column.id === 'fatura' ||
                                            column.id === 'total' ? (
                                            <div>
                                              {value.status === 'pago' ? (
                                                <div>
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        '#eeffee',
                                                      alignItems: 'center',
                                                      paddingLeft: 10,
                                                      paddingTop: 5,
                                                      paddingBottom: 0,
                                                      borderRadius: 5,
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          fontSize: 14,
                                                          marginTop: 0,
                                                          marginRight: 3,
                                                          color:
                                                            'rgba(57, 180, 13, 1)',
                                                        }}
                                                      >
                                                        R$
                                                      </p>
                                                      <p
                                                        style={{
                                                          fontSize: 20,
                                                          fontWeight: 'bold',
                                                          marginTop: -1,
                                                          color:
                                                            'rgba(57, 180, 13, 1)',
                                                        }}
                                                      >
                                                        {format(value.vlr)}
                                                      </p>
                                                    </div>
                                                    <p
                                                      style={{
                                                        marginTop: -20,
                                                        fontSize: 12,
                                                        color:
                                                          'rgba(57, 180, 13, 1)',
                                                      }}
                                                    >
                                                      Pago em {value.data}
                                                    </p>
                                                  </div>
                                                </div>
                                              ) : value.status ===
                                                'agendado' ? (
                                                <div
                                                  style={{
                                                    backgroundColor: '#f4f4f4',
                                                    alignItems: 'center',
                                                    paddingLeft: 10,
                                                    paddingTop: 5,
                                                    paddingBottom: 0,
                                                    borderRadius: 5,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        fontSize: 14,
                                                        marginTop: 0,
                                                        marginRight: 3,
                                                      }}
                                                    >
                                                      R$
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize: 20,
                                                        fontWeight: 'bold',
                                                        marginTop: -1,
                                                      }}
                                                    >
                                                      {format(value.vlr)}
                                                    </p>
                                                  </div>
                                                  <p
                                                    style={{
                                                      marginTop: -20,
                                                      fontSize: 12,
                                                      color: '#999999',
                                                    }}
                                                  >
                                                    Repasse previsto até{' '}
                                                    {value.data}
                                                  </p>
                                                </div>
                                              ) : value.status === 'aberto' ? (
                                                <div
                                                  style={{
                                                    backgroundColor: '#ffffff',
                                                    alignItems: 'center',
                                                    paddingLeft: 10,
                                                    paddingTop: 5,
                                                    paddingBottom: 0,
                                                    borderRadius: 5,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        fontSize: 14,
                                                        marginTop: 0,
                                                        marginRight: 3,
                                                      }}
                                                    >
                                                      R$
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize: 20,
                                                        fontWeight: 'bold',
                                                        marginTop: -1,
                                                      }}
                                                    >
                                                      {format(value.vlr)}
                                                    </p>
                                                  </div>
                                                  <p
                                                    style={{
                                                      marginTop: -20,
                                                      fontSize: 12,
                                                      color: '#999999',
                                                    }}
                                                  >
                                                    Em aberto
                                                  </p>
                                                </div>
                                              ) : value.status ===
                                                'atrasado' ? (
                                                <div
                                                  style={{
                                                    backgroundColor: '#fff4f4',
                                                    alignItems: 'center',
                                                    paddingLeft: 10,
                                                    paddingTop: 5,
                                                    paddingBottom: 0,
                                                    borderRadius: 5,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <p
                                                      style={{
                                                        fontSize: 14,
                                                        marginTop: 0,
                                                        marginRight: 3,
                                                        color: '#ff3333',
                                                      }}
                                                    >
                                                      R$
                                                    </p>
                                                    <p
                                                      style={{
                                                        fontSize: 20,
                                                        fontWeight: 'bold',
                                                        marginTop: -1,
                                                        color: '#ff3333',
                                                      }}
                                                    >
                                                      {format(value.vlr)}
                                                    </p>
                                                  </div>
                                                  <p
                                                    style={{
                                                      marginTop: -20,
                                                      fontSize: 12,
                                                      color: '#ff9999',
                                                    }}
                                                  >
                                                    Em atraso
                                                  </p>
                                                </div>
                                              ) : value.status ===
                                                'aguardando' ? (
                                                <div>
                                                  {value.vlr < 0 ? (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#ffffcc',
                                                        alignItems: 'center',
                                                        paddingLeft: 10,
                                                        paddingTop: 5,
                                                        paddingBottom: 0,
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            fontSize: 14,
                                                            marginTop: 0,
                                                            marginRight: 3,
                                                            color: '#ff6600',
                                                          }}
                                                        >
                                                          R$
                                                        </p>
                                                        <p
                                                          style={{
                                                            fontSize: 20,
                                                            fontWeight: 'bold',
                                                            marginTop: -1,
                                                            color: '#ff6600',
                                                          }}
                                                        >
                                                          {format(value.vlr)}
                                                        </p>
                                                      </div>
                                                      <p
                                                        style={{
                                                          marginTop: -20,
                                                          fontSize: 12,
                                                          color: '#ff6600',
                                                        }}
                                                      >
                                                        Saldo devedor
                                                      </p>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          '#e8ffff',
                                                        alignItems: 'center',
                                                        paddingLeft: 10,
                                                        paddingTop: 5,
                                                        paddingBottom: 0,
                                                        borderRadius: 5,
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            fontSize: 14,
                                                            marginTop: 0,
                                                            marginRight: 3,
                                                            color: '#06448e',
                                                          }}
                                                        >
                                                          R$
                                                        </p>
                                                        <p
                                                          style={{
                                                            fontSize: 20,
                                                            fontWeight: 'bold',
                                                            marginTop: -1,
                                                            color: '#06448e',
                                                          }}
                                                        >
                                                          {format(value.vlr)}
                                                        </p>
                                                      </div>
                                                      <p
                                                        style={{
                                                          marginTop: -20,
                                                          fontSize: 12,
                                                          color: '#06448e',
                                                        }}
                                                      >
                                                        Aguardando agendamento.
                                                        Previsão: até{' '}
                                                        {value.data}
                                                      </p>
                                                    </div>
                                                  )}
                                                </div>
                                              ) : null}
                                              {value.disableButton ? null : value.type ==
                                                'Pagar' ? (
                                                <Button
                                                  size={'small'}
                                                  variant="outlined"
                                                  color="secondary"
                                                  style={{
                                                    color: '#FF6600',
                                                    marginTop: 0,
                                                    paddingTop: 3,
                                                    fontSize: 10,
                                                    marginLeft: -5,
                                                  }}
                                                >
                                                  Pagar
                                                </Button>
                                              ) : value.type == '' ? null : (
                                                <Button
                                                  size={'small'}
                                                  variant="outlined"
                                                  color="primary"
                                                  style={{
                                                    color: '#06448e',
                                                    marginTop: 0,
                                                    paddingTop: 3,
                                                    fontSize: 10,
                                                    marginLeft: -5,
                                                  }}
                                                >
                                                  Solicitar repasse
                                                </Button>
                                              )}
                                            </div>
                                          ) : (
                                            'R$' + format(value)
                                          )}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              )}
            </div>
          )}
          <DialogFat
            docId={docId}
            bopen={openDialog}
            handleClose={() => setOpenDialog(false)}
            rowSelected={rowSelected}
            fatSelected={fatSelected}
            handleNotiStack={handleNotiStack}
            handleBackDrop={handleBackDrop}
            getFaturaMes={getFaturaMes}
            style={{
              top: 0,
              position: 'absolute',
              zIndex: 3,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Faturas;
