/* eslint-disable react/prop-types */
import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {FaBars} from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/pt';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector} from 'react-redux';
import {DataGrid, ptBR} from '@material-ui/data-grid';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {ptBR as corePtBR} from '@material-ui/core/locale';
import {getAuth} from 'firebase/auth';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import DialogGenerateOcorrenicia from '../../components/DialogGenerateOcorrenicia';

const theme = createTheme(
  {
    palette: {
      primary: {main: '#06448e'},
    },
  },
  ptBR,
  corePtBR,
);

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

const GetOcorrências = ({
  handleToggleSidebar,
  handleNotiStack,
  handleBackDrop,
}) => {
  const auth = getAuth();
  const classes = useStyles();
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const [isLoading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const idstore = useSelector(state => state.setrestaurante.idstore);
  const [DATA, setDATA] = useState({});
  const [isFirstOpen, setFirstOpen] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [openDialog, setOpenDialog] = useState(false);
  const [stt, setStt] = useState([]);
  const stts = [
    {
      title: 'Todas',
      value: '',
    },
    {
      title: 'Abertas',
      value: 'Aberto',
    },
    {
      title: 'Aceitas',
      value: 'Aceito',
    },
    {
      title: 'Negadas',
      value: 'Negado',
    },
  ];

  const handleChange = event => {
    setStt(event.target.value);
  };
  const [selectedInitialDate, setSelectedInitialDate] = React.useState(
    new Date(),
  );
  const handleInitialDateChange = date => {
    setSelectedInitialDate(date);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
  const handleEndDateChange = date => {
    setSelectedEndDate(date);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      scrollToTop();
    }, 1000);
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  // eslint-disable-next-line no-unused-vars
  const [width, height] = useWindowSize();

  function createData(npd, fday, strpt, sawsrpt, ssttrpt, _id) {
    return {npd, fday, strpt, sawsrpt, ssttrpt, _id};
  }

  const columns = [
    {
      field: 'npd',
      headerName: 'Pedido',
      width: isVerySmall || isSmall ? 150 : 180,
      editable: false,
    },
    {
      field: 'fday',
      headerName: 'Data',
      width: isVerySmall || isSmall ? 150 : 180,
      editable: false,
    },
    {
      field: 'strpt',
      headerName: 'Ocorrência',
      width: isVerySmall || isSmall ? 300 : 330,
      editable: false,
    },
    {
      field: 'sawsrpt',
      headerName: 'Resposta',
      width: isVerySmall || isSmall ? 150 : 180,
      editable: false,
      valueFormatter: params => {
        if (params.value == '') {
          return 'Aguardando resposta';
        } else {
          return params.value;
        }
      },
    },
    {
      field: 'ssttrpt',
      headerName: 'Status',
      width: isVerySmall || isSmall ? 150 : 180,
      editable: false,
    },
  ];

  const [rows, setRows] = useState([]);
  const [resultsSize, setResultsize] = useState(0);
  const [results, setResults] = useState([]);

  useEffect(() => {
    let map = [];
    if (results.length > 0) {
      // eslint-disable-next-line no-unused-vars
      map = results.map((item, index) => {
        return createData(
          item.npd,
          item.fday,
          item.strpt,
          item.sawsrpt,
          item.ssttrpt,
          item._id,
        );
      });
    }
    setRows(map);
    setResultsize(results.length);
  }, [results]);

  const handleOnCellClick = params => {
    let currentitem = {};
    console.log('params: ' + params.id);

    currentitem = results
      .filter(orders => orders.npd == params.id)
      .map(item => {
        return item;
      });
    console.log('current item: ' + currentitem);
    setDATA(currentitem[0]);
    setOpenDialog(true);
  };

  function onSearching() {
    setFirstOpen(false);
    setIsSearching(true);
    setResults([]);
    auth.currentUser.getIdToken().then(token => {
      const pedidosrequest = new Request(
        'https://kops.apiireit.com/gateway/pedidos/store/get/ocorrencias/filters?estid=' +
          idstore +
          '&stt=' +
          stt +
          '&sday=' +
          selectedInitialDate.getTime() +
          '&eday=' +
          selectedEndDate.getTime(),
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      fetch(pedidosrequest)
        .then(response => {
          if (response.status === 200) {
            handleBackDrop('', '', 200, false);
            response
              .json()
              .then(data => {
                setResults(data.data);
                setIsSearching(false);
                if (data.length == 0) {
                  handleNotiStack('Nenhum resultado encontrado');
                }
              })
              .catch(error => {
                setIsSearching(false);
                setResults([]);
                console.log(error);
              });
          } else if (response.status === 503) {
            handleBackDrop(
              'Serviço indisponível no momento',
              'O serviços para as lojas Irê it não estão disponíveis no momento. Por favor, tente novamente mais tarde',
              503,
              true,
            );
            throw new Error('Something went wrong on API server!');
          } else if (response.status === 404) {
            handleBackDrop(
              'Serviço não encontrado.',
              'Por favor, entre em contato com o nosso suporte e relate o problema.',
              404,
              true,
            );
          } else if (response.status === 401) {
            handleBackDrop(
              'Você não possui autorização ou suas credenciais expirarão.',
              'Por favor, atualize a baixa ou refaça o processo dee login.',
              401,
              true,
            );
          } else if (response.status === 400) {
            handleBackDrop(
              'Requisição inválida.',
              'Confira se todos os campos estão preenchidos corretamente.',
              400,
              true,
            );
          } else if (response.status === 500) {
            handleBackDrop(
              'Nosso servidor está passando por irregularidades.',
              'Estamos trabalhado para resolver o mais rápido possível.',
              500,
              true,
            );
          }
        })
        .catch(error => {
          handleBackDrop(
            'Nosso servidor está passando por irregularidades.',
            'Estamos trabalhado para resolver o mais rápido possível.',
            500,
            true,
          );
          console.error(error);
        });
    });
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: height,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVisible ? (
            <Fab
              onClick={() => scrollToTop()}
              style={{position: 'fixed', bottom: 20, right: 20, zIndex: 2}}
              size={'small'}
              color="primary"
              aria-label="add"
            >
              <ArrowUpwardIcon />
            </Fab>
          ) : null}
          <div
            style={{marginLeft: 10, marginTop: 10}}
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          >
            <FaBars />
          </div>
          {isVerySmall ? (
            <div>
              <h1 style={{marginLeft: 10, marginBottom: 5, color: '#393939'}}>
                Ocorrências{' '}
              </h1>
              <div
                style={{
                  marginTop: 15,
                  marginLeft: 10,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p1
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#393939',
                    alignItems: 'center',
                  }}
                >
                  Buscar ocorrências
                </p1>
              </div>
              <div style={{marginTop: 0, marginLeft: 10}}>
                <div style={{marginTop: 5, width: '90%'}}>
                  <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-dialog1"
                        label="Buscar ocorrências de"
                        value={selectedInitialDate}
                        onChange={handleInitialDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputProps={{
                          style: {
                            fontSize: 14,
                            height: 44,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{marginLeft: 0, marginTop: 0}}>
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        style={{marginLeft: 0}}
                        margin="normal"
                        id="date-picker-dialog2"
                        label="até"
                        format="dd/MM/yyyy"
                        minDate={selectedInitialDate}
                        minDateMessage={'Deve ser depois da data inicial'}
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputProps={{
                          style: {
                            fontSize: 14,
                            height: 44,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div>
                    <FormControl
                      style={{marginTop: 10, marginLeft: 0, width: '100%'}}
                      className={classes.formControl}
                    >
                      <InputLabel
                        style={{fontSize: 14}}
                        id="demo-simple-select-label"
                      >
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stt}
                        onChange={handleChange}
                        style={{marginTop: 14, width: '100%'}}
                      >
                        {(Array.isArray(stts)
                          ? stts.map(({title, value}) => [title, value])
                          : Object.entries(stts)
                        ).map(([title, value]) => {
                          return (
                            <MenuItem
                              style={{fontSize: 14}}
                              key={title}
                              value={value}
                            >
                              {title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div>
                  <Button
                    style={{marginLeft: 0, marginTop: 15}}
                    variant="contained"
                    size={'medium'}
                    color="primary"
                    onClick={() => onSearching()}
                  >
                    Buscar
                  </Button>
                </div>
                {isSearching ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: height / 2,
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div style={{marginLeft: 0, marginRight: 20, marginTop: 30}}>
                    {isFirstOpen ? (
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#393939',
                          fontSize: 18,
                          marginBottom: 80,
                        }}
                      >
                        Adicione as informações que você deseja e clique em
                        buscar.
                      </p>
                    ) : resultsSize === 0 ? (
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#393939',
                          fontSize: 18,
                          marginBottom: 80,
                        }}
                      >
                        Nenhuma ocorrência encontrada.
                      </p>
                    ) : (
                      <div>
                        {resultsSize === 1 ? (
                          <p
                            style={{
                              fontWeight: 'bold',
                              color: '#393939',
                              fontSize: 18,
                            }}
                          >
                            {resultsSize} Ocorrência encontrada
                          </p>
                        ) : (
                          <p
                            style={{
                              fontWeight: 'bold',
                              color: '#393939',
                              fontSize: 18,
                            }}
                          >
                            {resultsSize} Ocorrências encontradas
                          </p>
                        )}

                        <p
                          style={{
                            color: '#393939',
                            fontSize: 14,
                          }}
                        >
                          Aqui estão suas ocorrências
                        </p>
                        <div
                          style={{height: 400, width: '100%', marginBottom: 80}}
                        >
                          <ThemeProvider theme={theme}>
                            <DataGrid
                              sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: 'primary.light',
                                '& .MuiDataGrid-cell:hover': {
                                  color: 'primary',
                                },
                              }}
                              onCellClick={handleOnCellClick}
                              rows={rows}
                              columns={columns}
                              pageSize={20}
                              disableSelectionOnClick
                              getRowId={row => row.npd}
                            />
                          </ThemeProvider>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : isSmall ? (
            <div>
              <h1 style={{marginLeft: 10, marginBottom: 5, color: '#393939'}}>
                Avaliações{' '}
              </h1>
              <div
                style={{
                  marginTop: 15,
                  marginLeft: 10,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p1
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#393939',
                    alignItems: 'center',
                  }}
                >
                  Buscar ocorrências
                </p1>
              </div>
              <div style={{marginTop: 0, marginLeft: 10}}>
                <div style={{marginTop: 5, width: '90%'}}>
                  <div style={{marginLeft: 0}}>
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-dialog1"
                        label="Buscar ocorrências de"
                        value={selectedInitialDate}
                        onChange={handleInitialDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputProps={{
                          style: {
                            fontSize: 14,
                            height: 44,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{marginLeft: 0, marginTop: 0}}>
                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        style={{marginLeft: 0}}
                        margin="normal"
                        id="date-picker-dialog2"
                        label="até"
                        format="dd/MM/yyyy"
                        minDate={selectedInitialDate}
                        minDateMessage={'Deve ser depois da data inicial'}
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputProps={{
                          style: {
                            fontSize: 14,
                            height: 44,
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div>
                    <FormControl
                      style={{marginTop: 10, marginLeft: 0, width: 210}}
                      className={classes.formControl}
                    >
                      <InputLabel
                        style={{fontSize: 14}}
                        id="demo-simple-select-label"
                      >
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stt}
                        onChange={handleChange}
                        style={{marginTop: 14, width: '100%'}}
                      >
                        {(Array.isArray(stts)
                          ? stts.map(({title, value}) => [title, value])
                          : Object.entries(stts)
                        ).map(([title, value]) => {
                          return (
                            <MenuItem
                              style={{fontSize: 14}}
                              key={title}
                              value={value}
                            >
                              {title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div>
                  <Button
                    style={{marginLeft: 0, marginTop: 15}}
                    variant="contained"
                    size={'medium'}
                    color="primary"
                    onClick={() => onSearching()}
                  >
                    Buscar
                  </Button>
                </div>
                {isSearching ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: height / 2,
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div style={{marginLeft: 0, marginRight: 20, marginTop: 30}}>
                    {isFirstOpen ? (
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#393939',
                          fontSize: 18,
                          marginBottom: 80,
                        }}
                      >
                        Adicione as informações que você deseja e clique em
                        buscar.
                      </p>
                    ) : resultsSize === 0 ? (
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: '#393939',
                          fontSize: 18,
                        }}
                      >
                        Nenhuma ocorrência encontrada
                      </p>
                    ) : (
                      <div>
                        {resultsSize === 1 ? (
                          <p
                            style={{
                              fontWeight: 'bold',
                              color: '#393939',
                              fontSize: 18,
                            }}
                          >
                            {resultsSize} Ocorrência encontrada
                          </p>
                        ) : (
                          <p
                            style={{
                              fontWeight: 'bold',
                              color: '#393939',
                              fontSize: 18,
                            }}
                          >
                            {resultsSize} Ocorrências encontradas
                          </p>
                        )}

                        <p
                          style={{
                            color: '#393939',
                            fontSize: 14,
                          }}
                        >
                          Aqui estão suas ocorrências
                        </p>
                        <div
                          style={{height: 400, width: '100%', marginBottom: 80}}
                        >
                          <ThemeProvider theme={theme}>
                            <DataGrid
                              onCellClick={handleOnCellClick}
                              rows={rows}
                              columns={columns}
                              pageSize={20}
                              disableSelectionOnClick
                              getRowId={row => row.npd}
                            />
                          </ThemeProvider>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <h1 style={{marginLeft: 10, marginBottom: 5, color: '#393939'}}>
                Ocorrências
              </h1>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <div style={{width: '100%'}}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 15,
                    }}
                  >
                    <p1
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#393939',
                        alignItems: 'center',
                      }}
                    >
                      Buscar ocorrências
                    </p1>
                  </div>
                  <div
                    style={{
                      marginLeft: 20,
                      marginRight: 20,
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div style={{display: 'flex'}}>
                      <div style={{marginLeft: 0, marginTop: -18}}>
                        <MuiPickersUtilsProvider
                          locale={deLocale}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-dialog1"
                            label="Buscar Ocorrências de"
                            value={selectedInitialDate}
                            onChange={handleInitialDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div style={{marginLeft: 0, marginTop: -18}}>
                        <MuiPickersUtilsProvider
                          locale={deLocale}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            style={{marginLeft: 20}}
                            margin="normal"
                            id="date-picker-dialog2"
                            label="até"
                            format="dd/MM/yyyy"
                            minDate={selectedInitialDate}
                            minDateMessage={'Deve ser depois da data inicial'}
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <FormControl
                          style={{marginTop: 0, marginLeft: 20, width: 200}}
                          className={classes.formControl}
                        >
                          <InputLabel
                            style={{fontSize: 14}}
                            id="demo-simple-select-label"
                          >
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={stt}
                            onChange={handleChange}
                            style={{marginTop: 14, width: '100%'}}
                          >
                            {(Array.isArray(stts)
                              ? stts.map(({title, value}) => [title, value])
                              : Object.entries(stts)
                            ).map(([title, value]) => {
                              return (
                                <MenuItem
                                  style={{fontSize: 14}}
                                  key={title}
                                  value={value}
                                >
                                  {title}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button
                      style={{marginLeft: 15, marginTop: 15}}
                      variant="contained"
                      size={'medium'}
                      color="primary"
                      onClick={() => onSearching()}
                    >
                      Buscar
                    </Button>
                  </div>
                  {isSearching ? (
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: height / 2,
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <div
                      style={{marginLeft: 20, marginRight: 20, marginTop: 30}}
                    >
                      {isFirstOpen ? (
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            fontSize: 18,
                            marginBottom: 80,
                          }}
                        >
                          Adicione as informações que você deseja e clique em
                          buscar.
                        </p>
                      ) : resultsSize === 0 ? (
                        <p
                          style={{
                            fontWeight: 'bold',
                            color: '#393939',
                            fontSize: 18,
                          }}
                        >
                          Nenhuma ocorrência encontrada
                        </p>
                      ) : (
                        <div>
                          {resultsSize === 1 ? (
                            <p
                              style={{
                                fontWeight: 'bold',
                                color: '#393939',
                                fontSize: 18,
                              }}
                            >
                              {resultsSize} Ocorrência encontrada
                            </p>
                          ) : (
                            <p
                              style={{
                                fontWeight: 'bold',
                                color: '#393939',
                                fontSize: 18,
                              }}
                            >
                              {resultsSize} Ocorrências encontradas
                            </p>
                          )}

                          <p
                            style={{
                              color: '#393939',
                              fontSize: 14,
                            }}
                          >
                            Aqui estão suas ocorrências
                          </p>
                          <div
                            style={{
                              height: 300,
                              width: '100%',
                            }}
                          >
                            <ThemeProvider theme={theme}>
                              <DataGrid
                                onCellClick={handleOnCellClick}
                                rows={rows}
                                columns={columns}
                                pageSize={20}
                                disableSelectionOnClick
                                getRowId={row => row.npd}
                              />
                            </ThemeProvider>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <DialogGenerateOcorrenicia
            bopen={openDialog}
            handleClose={() => setOpenDialog(false)}
            handleNotiStack={handleNotiStack}
            DATA={DATA}
            setDATA={setDATA}
            updateList={onSearching}
            style={{
              top: 0,
              position: 'absolute',
              zIndex: 3,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GetOcorrências;
