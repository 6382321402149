/* eslint-disable react/prop-types */
import {Box, Divider} from '@material-ui/core';
import React from 'react';
import {realFormat} from '../../configuration/realFormat';
import {dayformat} from '../../configuration/dayformat';

const RepasseBox = ({currentRepasse, boxWidth}) => {
  return (
    <div style={{width: boxWidth}}>
      <Box
        style={{
          marginLeft: 0,
          marginRight: 0,
          borderRadius: 10,
        }}
        boxShadow={3}
      >
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: '#f1f1f1',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          }}
        >
          <p style={{marginBottom: 0}}>Informações do repasse</p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                marginTop: 0,
                paddingBottom: 0,
              }}
            >
              Prazo para receber
            </p>
            <p
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
                fontSize: 14,
                color: '#393939',
                marginTop: 0,
                marginBottom: 0,
                paddingBottom: 0,
                textAlign: 'right',
              }}
            >
              Até dia: {dayformat(currentRepasse.exp)}
            </p>
          </div>
          <Divider variant="fullWidth" style={{marginTop: 10}} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              style={{
                fontWeight: 'normal',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              Descrição
            </p>
            <p
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
                textAlign: 'right',
              }}
            >
              Repasse da fatura {dayformat(currentRepasse.sday)} -{' '}
              {dayformat(currentRepasse.eday)}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              style={{
                fontWeight: 'normal',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              Pix do depósito
            </p>
            <p
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
                textAlign: 'right',
              }}
            >
              {currentRepasse.pix}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              style={{
                fontWeight: 'normal',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              Valor inicial
            </p>
            <p
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
                textAlign: 'right',
              }}
            >
              {realFormat(currentRepasse.vlro)}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              style={{
                fontWeight: 'normal',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              Taxa de antecipação
            </p>
            <p
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
                fontSize: 14,
                color: '#393939',
                marginBottom: 0,
                paddingBottom: 0,
                textAlign: 'right',
              }}
            >
              {realFormat(currentRepasse.vlr * currentRepasse.txantcp)}
            </p>
          </div>
          <Divider variant="fullWidth" style={{marginTop: 10}} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              style={{
                fontWeight: 'normal',
                fontSize: 16,
                color: '#000000',
                marginBottom: 10,
                marginTop: 5,
                paddingBottom: 0,
              }}
            >
              Valor do repasse
            </p>
            <p
              style={{
                marginLeft: 10,
                fontWeight: 'bold',
                fontSize: 16,
                color: '#000000',
                marginBottom: 10,
                marginTop: 5,
                paddingBottom: 0,
                textAlign: 'right',
              }}
            >
              {realFormat(currentRepasse.vlr)}
            </p>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default RepasseBox;
