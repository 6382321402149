/* eslint-disable react/prop-types */
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckIcon from '@material-ui/icons/Check';
import logo from '../../assets/logo.png';
import {Box, Button, Typography} from '@material-ui/core';
import AlertSending from '../../components/AlertSeding';

const Confirmemail = ({
  isEmailVerified,
  errorSendEmailVerified,
  handleResendVerification,
  count,
  setCount,
  isSending,
  setIsSending,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  useEffect(() => {
    if (count > 0) {
      const timer = setInterval(() => {
        setCount(prevCount => (prevCount > 0 ? prevCount - 1 : 0));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [count, setCount]);

  return (
    <div
      style={{
        width: width,
        height: height,
        background: 'linear-gradient(#06448e, #7c53e3, #b553e3)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Box
        boxShadow={3}
        borderRadius={1}
        style={{
          background: 'rgba(255, 255, 255, 0.725)',
          marginLeft: isVerySmall || isSmall ? 20 : 30,
          marginRight: isVerySmall || isSmall ? 20 : 30,
          padding: isVerySmall || isSmall ? 15 : 20,
        }}
      >
        {isEmailVerified ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: isVerySmall || isSmall ? '100%' : 275,
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              style={{
                width: 100,
                height: 50,
                background: '#06448e',
                padding: 10,
                borderRadius: 5,
              }}
            />
            <CheckIcon
              style={{color: '#52d869', fontSize: 50, marginTop: 25}}
            />
            <Typography variant="h6" align="center">
              Email confirmado com sucesso!
            </Typography>
          </div>
        ) : errorSendEmailVerified ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: isVerySmall || isSmall ? '100%' : 275,
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              style={{
                width: 100,
                height: 50,
                background: '#06448e',
                padding: 10,
                borderRadius: 5,
              }}
            />
            <InfoOutlinedIcon
              style={{color: '#ff6600', fontSize: 50, marginTop: 20}}
            />
            <Typography variant="h6" align="center">
              Erro ao enviar verificação de email. Por favor, verifique sua
              conexão e tente novamente
            </Typography>
            <Typography variant="body1" align="center">
              Houve um problema no envio da verificação do seu email.
            </Typography>
            {count > 0 ? (
              <div>
                <Typography
                  style={{marginTop: 30}}
                  variant="body1"
                  align="center"
                >
                  Você poderá solicitar o envio do email novamente em
                </Typography>
                <Typography
                  style={{fontSize: 28, fontWeight: 'bold'}}
                  align="center"
                >
                  {count}
                </Typography>
                <Typography variant="body1" align="center">
                  Segundos
                </Typography>
              </div>
            ) : null}
            <Button
              variant="contained"
              color="primary"
              onClick={handleResendVerification}
              style={{marginTop: 20}}
              disabled={count > 0}
            >
              Reenviar Verificação
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: isVerySmall || isSmall ? '100%' : 275,
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              style={{
                width: 100,
                height: 50,
                background: '#06448e',
                padding: 10,
                borderRadius: 5,
              }}
            />
            <CheckIcon
              style={{color: '#52d869', fontSize: 50, marginTop: 25}}
            />
            <Typography variant="h6" align="center">
              Email enviado com sucesso!
            </Typography>
            <Typography variant="body1" align="center">
              Caso tenha algum problema. Solicite o reenvio do email
            </Typography>
            {count > 0 ? (
              <div>
                <Typography
                  style={{marginTop: 30}}
                  variant="body1"
                  align="center"
                >
                  Você poderá solicitar o envio do email novamente em
                </Typography>
                <Typography
                  style={{fontSize: 28, fontWeight: 'bold'}}
                  align="center"
                >
                  {count}
                </Typography>
                <Typography variant="body1" align="center">
                  Segundos
                </Typography>
              </div>
            ) : null}

            <Button
              variant="contained"
              color="primary"
              onClick={handleResendVerification}
              style={{marginTop: 30}}
              disabled={count > 0}
            >
              Reenviar Verificação
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleResendVerification}
              style={{marginTop: 30}}
            >
              Avisar que o email ja foi verificado
            </Button>
          </div>
        )}
      </Box>

      <AlertSending
        bopen={isSending}
        handleClose={() => setIsSending(false)}
        style={{
          top: 0,
          position: 'absolute',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default Confirmemail;
