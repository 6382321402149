/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useMediaQuery} from 'react-responsive';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SyncIcon from '@material-ui/icons/Sync';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckRepasse from './checkRepasse';
import Confirmation from './confirmation';
import DadosBancarios from './dadosBancarios';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  tabs: {
    fontSize: 14,
  },
  labelContainer: {
    padding: 0,
  },
  tab: {
    fontSize: 7,
    minWidth: '33.3%',
    width: '33.3%',
    minHeight: 0,
    height: 70,
    fontWeight: 'bold',
  },
}));

export default function RepasseTabs({
  value,
  handleChange,
  handleChangeIndex,
  isCheckedRepasse,
  setCheckedRepasse,
  loadingCheckRepasse,
  setCheckRepasse,
  currentRepasse,
  setCurrentRepasse,
  haveRepasse,
  fatSelected,
  handleNotiStack,
  isCheckedDB,
  loadingPageConfirm,
  setLoadingPageConfirm,
  repasseIsSent,
  setRepasseIsSent,
  requestRepasse,
  loadingRequestRepasse,
  checkIfRequestRepasseIsDone,
  txantcp,
  handleChangeTxantcp,
  contestRepasse,
  setLoadingRequestRepasse,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery({query: '(max-width: 767.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});

  return (
    <div style={{margintop: 0, paddingTop: 0}} className={classes.root}>
      <AppBar
        position={isVerySmall ? 'fixed' : isSmall ? 'fixed' : 'static'}
        color="default"
      >
        <Tabs
          className={classes.tabs}
          labelContainer={classes.labelContainer}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="full width tabs example"
        >
          <Tab
            labelContainer={classes.labelContainer}
            className={classes.tab}
            icon={<SyncIcon />}
            label="Verificação"
            {...a11yProps(0)}
          />
          {!haveRepasse ? (
            <Tab
              className={classes.tab}
              icon={<AccountBalanceWalletIcon />}
              label="Dados Bancários"
              {...a11yProps(1)}
            />
          ) : null}

          {!haveRepasse && isCheckedDB ? (
            <Tab
              className={classes.tab}
              icon={<AttachMoneyIcon />}
              label="Confirmação"
              {...a11yProps(2)}
            />
          ) : null}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={0}
          dir={theme.direction}
        >
          <CheckRepasse
            value={value}
            handleChange={handleChange}
            handleChangeIndex={handleChangeIndex}
            loadingCheckRepasse={loadingCheckRepasse}
            setCheckRepasse={setCheckRepasse}
            currentRepasse={currentRepasse}
            setCurrentRepasse={setCurrentRepasse}
            isCheckedRepasse={isCheckedRepasse}
            setCheckedRepasse={setCheckedRepasse}
            haveRepasse={haveRepasse}
            checkIfRequestRepasseIsDone={checkIfRequestRepasseIsDone}
            contestRepasse={contestRepasse}
          />
        </TabPanel>
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={1}
          dir={theme.direction}
        >
          <DadosBancarios
            fatSelected={fatSelected}
            txantcp={txantcp}
            handleChangeTxantcp={handleChangeTxantcp}
          />
        </TabPanel>
        <TabPanel
          style={{overflowX: 'hidden'}}
          value={value}
          index={2}
          dir={theme.direction}
        >
          <Confirmation
            fatSelected={fatSelected}
            value={value}
            handleNotiStack={handleNotiStack}
            currentRepasse={currentRepasse}
            loadingPageConfirm={loadingPageConfirm}
            setLoadingPageConfirm={setLoadingPageConfirm}
            repasseIsSent={repasseIsSent}
            setRepasseIsSent={setRepasseIsSent}
            requestRepasse={requestRepasse}
            loadingRequestRepasse={loadingRequestRepasse}
            setLoadingRequestRepasse={setLoadingRequestRepasse}
            txantcp={txantcp}
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
