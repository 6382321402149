/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import {Button, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {useSelector} from 'react-redux';
import {format} from '../../../configuration/format';
import RepasseBox from '../repasseBox';

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  checkIcon: {
    fontSize: 36,
    paddingRight: 4,
    marginLeft: 5,
    color: '#39B40D',
  },
  noPayIcon: {
    fontSize: 36,
    paddingRight: 4,
    marginLeft: 5,
    color: '#FF6600',
  },
}));

// eslint-disable-next-line react/prop-types
const Confirmation = ({
  isSendingRepasse,
  currentRepasse,
  value,
  handleNotiStack,
  loadingPageConfirm,
  setLoadingPageConfirm,
  repasseIsSent,
  setRepasseIsSent,
  fatSelected,
  requestRepasse,
  loadingRequestRepasse,
  setLoadingRequestRepasse,
  txantcp,
}) => {
  const isSmall = useMediaQuery({query: '(max-width: 750.98px)'});
  const isVerySmall = useMediaQuery({query: '(max-width:450.00px)'});
  const pix = useSelector(state => state.setrestaurante.cpix);
  const [width, height] = useWindowSize();
  const classes = useStyles();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    setLoadingPageConfirm(true);
    setTimeout(() => {
      setLoadingRequestRepasse(false);
      setLoadingPageConfirm(false);
    }, 500);
  }, []);

  useEffect(() => {
    setLoadingPageConfirm(true);
    setTimeout(() => {
      setLoadingRequestRepasse(false);
      setLoadingPageConfirm(false);
    }, 500);
  }, [value]);

  return (
    <div>
      {loadingPageConfirm || loadingRequestRepasse ? (
        <div
          style={{
            display: 'flex',
            width: isVerySmall ? '45%' : '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          {isVerySmall ? (
            <div style={{paddingTop: 30, width: width * 0.8}}>
              <div>
                {repasseIsSent ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 30,
                      }}
                    >
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: 18,
                        }}
                      >
                        O repasse ja foi enviado!
                      </FormLabel>
                      <CheckCircleOutlineIcon
                        color="primary"
                        className={classes.checkIcon}
                        style={{margin: 10}}
                      />
                    </div>
                    <RepasseBox
                      currentRepasse={currentRepasse}
                      boxWidth="95%"
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#FFFFFF',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left',
                      }}
                    >
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          fontSize: 18,
                        }}
                      >
                        Confirme as informações e clique em solicitar repasse
                      </FormLabel>
                      <TextField
                        style={{
                          width: isVerySmall ? '100%' : isSmall ? '100%' : 200,
                          marginTop: 10,
                        }}
                        InputProps={{
                          style: {
                            color: '#3C3C3C',
                            backgroundColor: '#F5F5F5',
                          },
                        }}
                        inputProps={{
                          style: {fontSize: 18},
                          maxLength: 150,
                          color: '#3C3C3C',
                        }}
                        InputLabelProps={{
                          style: {fontSize: 18, color: '#3C3C3C'},
                        }}
                        id="pix"
                        label="Chave pix"
                        defaultValue={pix}
                        value={pix}
                        disabled
                      />
                      <div
                        style={{
                          alignItems: 'center',
                          paddingLeft: 0,
                          marginTop: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                          borderRadius: 5,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p
                          style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                          }}
                        >
                          Total
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              fontSize: 20,
                              fontWeight: 'bold',
                            }}
                          >
                            R${' '}
                            {txantcp == 'comTaxa'
                              ? format(
                                  fatSelected.total -
                                    fatSelected.total * fatSelected.txatcp,
                                )
                              : format(fatSelected.total)}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: '#e8ffff',
                          display: 'flex',
                          marginTop: 10,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 5,
                        }}
                      >
                        <Radio checked={true} color="primary" />
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'normal',
                            fontSize: 14,
                            textAlign: 'center',
                            wordWrap: 'break-word',
                            width: 200,
                          }}
                        >
                          A Irê it leva até 5 dias úteis para realizar o
                          repasse.
                        </FormLabel>
                      </div>
                      <Button
                        style={{marginTop: 20}}
                        size={'small'}
                        onClick={() => requestRepasse()}
                        color="primary"
                      >
                        Solicitar repasse
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : isSmall ? (
            <div style={{width: width * 0.675, paddingTop: 30}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  paddingBottom: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <div>
                  {repasseIsSent ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'center',
                      }}
                    >
                      <FormLabel
                        component="legend"
                        style={{
                          color: '#3d3d3d',
                          fontWeight: 'bold',
                          marginTop: 10,
                          textAlign: 'center',
                          fontSize: 18,
                        }}
                      >
                        O repasse ja foi enviado!
                      </FormLabel>
                      <CheckCircleOutlineIcon
                        color="primary"
                        className={classes.checkIcon}
                        style={{margin: 10}}
                      />
                      <RepasseBox
                        currentRepasse={currentRepasse}
                        boxWidth="95%"
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#FFFFFF',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'left',
                        }}
                      >
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'bold',
                            fontSize: 18,
                          }}
                        >
                          Confirme as informações e clique em solicitar repasse
                        </FormLabel>
                        <TextField
                          style={{
                            width: isVerySmall
                              ? '100%'
                              : isSmall
                              ? '100%'
                              : 200,
                            marginTop: 10,
                          }}
                          InputProps={{
                            style: {
                              color: '#3C3C3C',
                              backgroundColor: '#F5F5F5',
                            },
                          }}
                          inputProps={{
                            style: {fontSize: 18},
                            maxLength: 150,
                            color: '#3C3C3C',
                          }}
                          InputLabelProps={{
                            style: {fontSize: 18, color: '#3C3C3C'},
                          }}
                          id="pix"
                          label="Chave pix"
                          defaultValue={pix}
                          value={pix}
                          disabled
                        />
                        <div
                          style={{
                            alignItems: 'center',
                            paddingLeft: 0,
                            marginTop: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            borderRadius: 5,
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 'bold',
                            }}
                          >
                            Total
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                              }}
                            >
                              R${' '}
                              {txantcp == 'comTaxa'
                                ? format(
                                    fatSelected.total -
                                      fatSelected.total * fatSelected.txatcp,
                                  )
                                : format(fatSelected.total)}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: '#e8ffff',
                            display: 'flex',
                            marginTop: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 5,
                          }}
                        >
                          <Radio checked={true} color="primary" />
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 14,
                              textAlign: 'center',
                              wordWrap: 'break-word',
                              width: 200,
                            }}
                          >
                            A Irê it leva até 5 dias úteis para realizar o
                            repasse.
                          </FormLabel>
                        </div>
                        <Button
                          style={{marginTop: 20}}
                          size={'small'}
                          onClick={() => requestRepasse()}
                          color="primary"
                        >
                          Solicitar repasse
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#FFFFFF',
                  paddingBottom: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <div>
                  {repasseIsSent ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: 30,
                        }}
                      >
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            fontSize: 18,
                          }}
                        >
                          O repasse ja foi solicitado
                        </FormLabel>
                        <CheckCircleOutlineIcon
                          color="primary"
                          className={classes.checkIcon}
                          style={{margin: 10}}
                        />
                      </div>
                      <div
                        style={{
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <RepasseBox
                          currentRepasse={currentRepasse}
                          boxWidth="98%"
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#FFFFFF',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'left',
                        }}
                      >
                        <FormLabel
                          component="legend"
                          style={{
                            color: '#3d3d3d',
                            fontWeight: 'bold',
                            fontSize: 18,
                          }}
                        >
                          Confirme as informações e clique em solicitar repasse
                        </FormLabel>
                        <TextField
                          style={{
                            width: isVerySmall
                              ? '100%'
                              : isSmall
                              ? '100%'
                              : 200,
                            marginTop: 10,
                          }}
                          InputProps={{
                            style: {
                              color: '#3C3C3C',
                              backgroundColor: '#F5F5F5',
                            },
                          }}
                          inputProps={{
                            style: {fontSize: 18},
                            maxLength: 150,
                            color: '#3C3C3C',
                          }}
                          InputLabelProps={{
                            style: {fontSize: 18, color: '#3C3C3C'},
                          }}
                          id="pix"
                          label="Chave pix"
                          defaultValue={pix}
                          value={pix}
                          disabled
                        />
                        <div
                          style={{
                            alignItems: 'center',
                            paddingLeft: 0,
                            marginTop: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            borderRadius: 5,
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 'bold',
                            }}
                          >
                            Total
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                              }}
                            >
                              R${' '}
                              {txantcp == 'comTaxa'
                                ? format(
                                    fatSelected.total -
                                      fatSelected.total * fatSelected.txatcp,
                                  )
                                : format(fatSelected.total)}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: '#e8ffff',
                            display: 'flex',
                            marginTop: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 5,
                          }}
                        >
                          <Radio checked={true} color="primary" />
                          <FormLabel
                            component="legend"
                            style={{
                              color: '#3d3d3d',
                              fontWeight: 'normal',
                              fontSize: 14,
                              textAlign: 'center',
                              wordWrap: 'break-word',
                              width: 200,
                            }}
                          >
                            A Irê it leva até 5 dias úteis para realizar o
                            repasse.
                          </FormLabel>
                        </div>
                        <Button
                          style={{marginTop: 20}}
                          size={'small'}
                          onClick={() => requestRepasse()}
                          color="primary"
                        >
                          Solicitar repasse
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div></div>
        </div>
      )}
    </div>
  );
};

export default Confirmation;
